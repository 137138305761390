import {
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
} from "./actionTypes";

export const fetchCustomersRequest = (companyId) => ({
  type: FETCH_CUSTOMERS_REQUEST,
  payload: { companyId },
});

export const fetchCustomersSuccess = (customers) => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const fetchCustomersFailure = (error) => ({
  type: FETCH_CUSTOMERS_FAILURE,
  payload: error,
});

export const fetchCustomerRequest = (vendorID, customerId) => ({
  type: FETCH_CUSTOMER_REQUEST,
  payload: { vendorID, customerId },
});

export const fetchCustomerSuccess = (customer) => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload: customer,
});

export const fetchCustomerFailure = (error) => ({
  type: FETCH_CUSTOMER_FAILURE,
  payload: error,
});

export const addCustomerRequest = (customer, navigate) => ({
  type: ADD_CUSTOMER_REQUEST,
  payload: { customer, navigate },
});

export const addCustomerSuccess = (customer) => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: customer,
});

export const addCustomerFailure = (error) => ({
  type: ADD_CUSTOMER_FAILURE,
  payload: error,
});

export const updateCustomerRequest = (customer, navigate) => ({
  type: UPDATE_CUSTOMER_REQUEST,
  payload: { customer, navigate },
});

export const updateCustomerSuccess = (customer) => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
});

export const updateCustomerFailure = (error) => ({
  type: UPDATE_CUSTOMER_FAILURE,
  payload: error,
});