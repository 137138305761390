import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  FormGroup,
  FormText
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addPartnerRequest } from "../../store/partners/actions";
import IMPartnerSelector from "../../components/ui/IMPartnerSelector";
import IMMasterDropdown from "../../components/ui/IMMasterDropdown";
import IMMasterMultiSelect from "../../components/ui/IMMasterMultiSelect"; // Import the multi-select component
import IMLocation from "../../components/ui/IMLocation";
import { useNavigate } from "react-router-dom";
import IMPhoto from "../../components/ui/IMPhoto";

const AddNewPartner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.partners);

  const validationSchema = Yup.object({
    title: Yup.string().required("Please Enter Partner Name"),
    email: Yup.string().email("Invalid email format").required("Please Enter Email"),
    status: Yup.string().required("Please Select Status"),
    services: Yup.array().min(1, "Select at least one service").required("Please Select Services"),
    location: Yup.object().shape({
      label: Yup.string().required("Please select a location")
    }).nullable().required("Please select a location"),
  });

  const handleSubmit = (values) => {
    const partnerData = {
      ...values,
    };
    dispatch(addPartnerRequest(partnerData, navigate));
  };

  const initialValues = {
    title: "",
    email: "",
    status: "",
    services: [],
    location: null,
    photo: "",
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Partners" breadcrumbItem="Add New Partner" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="name">Partner Name</Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            invalid={formik.touched.title && !!formik.errors.title}
                          />
                          <FormFeedback>{formik.errors.title}</FormFeedback>
                          <FormText color="muted">Enter the name of the partner.</FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            invalid={formik.touched.email && !!formik.errors.email}
                          />
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                          <FormText color="muted">Enter the email address of the partner.</FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                        <IMMasterDropdown
                            collectionName="status"
                            selectedValue={formik.values.status}
                            onChange={(option) => formik.setFieldValue("status", option.label)}
                            label="Status"
                          />
                          <FormFeedback>{formik.errors.status}</FormFeedback>
                          <FormText color="muted">Select the status of the partner.</FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <IMMasterMultiSelect
                            collectionName="services"
                            selectedValues={formik.values.services}
                            onChange={(value) => formik.setFieldValue("services", value)}
                            label="Services"
                          />
                          <FormFeedback>{formik.errors.services}</FormFeedback>
                          <FormText color="muted">Select one or more services for the partner.</FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <IMLocation
                         label="Location"                        
                           selectedLocation={formik.values.location}
                          onLocationSelect={(location) => formik.setFieldValue("location", location)}
                          formik={formik}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <IMPhoto
                          currentPhoto={formik.values.photo}
                          onUpload={(url) => formik.setFieldValue("photo", url)}
                          fieldName="photo"
                          formik={formik}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? "Loading..." : "Create"}
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      className="ml-2"
                      onClick={() => window.history.back()}
                    >
                      Back to Partner List
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddNewPartner;