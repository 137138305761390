import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRolesRequest } from '../../store/roles/actions';

const IMRolesSelectComponent = ({ selectedRoles, onChange }) => {
  const dispatch = useDispatch();
  const [typeaheadValue, setTypeaheadValue] = useState('');
  const rolesState = useSelector(state => state.roles);
  const roles = rolesState.roles && Array.isArray(rolesState.roles.roles) ? rolesState.roles.roles : [];
  const loading = useSelector(state => state.roles.loading);
  const error = useSelector(state => state.roles.error);

  useEffect(() => {
    dispatch(fetchRolesRequest(typeaheadValue));
  }, [dispatch, typeaheadValue]);

  const handleInputChange = (newValue) => {
    setTypeaheadValue(newValue);
  };

  const handleMulti = (selectedOptions) => {
    onChange(selectedOptions ? selectedOptions.map(option => ({ id: option.value, role: option.label })) : []);
  };

  const optionGroup = roles.map(role => ({ value: role.id, label: role.role }));

  if (loading) {
    return <div>Loading roles...</div>;
  }

  if (error) {
    return <div>Error loading roles: {error}</div>;
  }

  return (
    <div className="mb-3">
      <label className="control-label">Select Roles</label>
      <Select
        value={selectedRoles.map(role => ({ value: role.id, label: role.role }))}
        isMulti={true}
        onChange={handleMulti}
        options={optionGroup}
        onInputChange={handleInputChange}
        isLoading={loading}
        classNamePrefix="select2-selection"
        placeholder="Search for roles..."
      />
    </div>
  );
};

export default IMRolesSelectComponent;