export const FETCH_MASTER_ITEMS_REQUEST = 'FETCH_MASTER_ITEMS_REQUEST';
export const FETCH_MASTER_ITEMS_SUCCESS = 'FETCH_MASTER_ITEMS_SUCCESS';
export const FETCH_MASTER_ITEMS_FAILURE = 'FETCH_MASTER_ITEMS_FAILURE';

export const ADD_MASTER_ITEM_REQUEST = 'ADD_MASTER_ITEM_REQUEST';
export const ADD_MASTER_ITEM_SUCCESS = 'ADD_MASTER_ITEM_SUCCESS';
export const ADD_MASTER_ITEM_FAILURE = 'ADD_MASTER_ITEM_FAILURE';

export const UPDATE_MASTER_ITEM_REQUEST = 'UPDATE_MASTER_ITEM_REQUEST';
export const UPDATE_MASTER_ITEM_SUCCESS = 'UPDATE_MASTER_ITEM_SUCCESS';
export const UPDATE_MASTER_ITEM_FAILURE = 'UPDATE_MASTER_ITEM_FAILURE';

export const DELETE_MASTER_ITEM_REQUEST = 'DELETE_MASTER_ITEM_REQUEST';
export const DELETE_MASTER_ITEM_SUCCESS = 'DELETE_MASTER_ITEM_SUCCESS';
export const DELETE_MASTER_ITEM_FAILURE = 'DELETE_MASTER_ITEM_FAILURE';

export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE';

export const FETCH_SERVICES_REQUEST = "FETCH_SERVICES_REQUEST";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const FETCH_SERVICES_FAILURE = "FETCH_SERVICES_FAILURE";

export const FETCH_STATUS_REQUEST = "FETCH_STATUS_REQUEST";
export const FETCH_STATUS_SUCCESS = "FETCH_STATUS_SUCCESS";
export const FETCH_STATUS_FAILURE = "FETCH_STATUS_FAILURE";

export const FETCH_SUBCATEGORIES_REQUEST = 'FETCH_SUBCATEGORIES_REQUEST';
export const FETCH_SUBCATEGORIES_SUCCESS = 'FETCH_SUBCATEGORIES_SUCCESS';
export const FETCH_SUBCATEGORIES_FAILURE = 'FETCH_SUBCATEGORIES_FAILURE';

export const ADD_SUBCATEGORY_REQUEST = 'ADD_SUBCATEGORY_REQUEST';
export const ADD_SUBCATEGORY_SUCCESS = 'ADD_SUBCATEGORY_SUCCESS';
export const ADD_SUBCATEGORY_FAILURE = 'ADD_SUBCATEGORY_FAILURE';

export const UPDATE_SUBCATEGORY_REQUEST = 'UPDATE_SUBCATEGORY_REQUEST';
export const UPDATE_SUBCATEGORY_SUCCESS = 'UPDATE_SUBCATEGORY_SUCCESS';
export const UPDATE_SUBCATEGORY_FAILURE = 'UPDATE_SUBCATEGORY_FAILURE';

export const DELETE_SUBCATEGORY_REQUEST = 'DELETE_SUBCATEGORY_REQUEST';
export const DELETE_SUBCATEGORY_SUCCESS = 'DELETE_SUBCATEGORY_SUCCESS';
export const DELETE_SUBCATEGORY_FAILURE = 'DELETE_SUBCATEGORY_FAILURE';