import React from 'react';
import IMMasterTable from '../../components/ui/IMMasterTable';
import {
    Row,
    Card,
    CardBody,
   
  } from "reactstrap";

const MasterTable = () => {
  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <div>
            <Row>
              <IMMasterTable collectionName="status" />
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  )
};

export default  MasterTable;