import React from 'react';
import { Row, Col, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { useFormikContext } from 'formik';

const CustomerMemberDetailsStep = () => {
  const formik = useFormikContext();

  return (
    <div>
      <h5>Customer Details</h5>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="customerName">Customer Name</Label>
            <Input
              id="customerName"
              name="customerName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customerName}
              invalid={formik.touched.customerName && !!formik.errors.customerName}
            />
            <FormFeedback>{formik.errors.customerName}</FormFeedback>
            <FormText color="muted">Enter the name of the customer.</FormText>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="customerEmail">Customer Email</Label>
            <Input
              id="customerEmail"
              name="customerEmail"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customerEmail}
              invalid={formik.touched.customerEmail && !!formik.errors.customerEmail}
            />
            <FormFeedback>{formik.errors.customerEmail}</FormFeedback>
            <FormText color="muted">Enter the email address of the customer.</FormText>
          </FormGroup>
        </Col>
      </Row>

      <h5>Member Details</h5>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="memberFirstName">First Name</Label>
            <Input
              id="memberFirstName"
              name="memberFirstName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.memberFirstName}
              invalid={formik.touched.memberFirstName && !!formik.errors.memberFirstName}
            />
            <FormFeedback>{formik.errors.memberFirstName}</FormFeedback>
            <FormText color="muted">Enter the first name of the member.</FormText>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="memberLastName">Last Name</Label>
            <Input
              id="memberLastName"
              name="memberLastName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.memberLastName}
              invalid={formik.touched.memberLastName && !!formik.errors.memberLastName}
            />
            <FormFeedback>{formik.errors.memberLastName}</FormFeedback>
            <FormText color="muted">Enter the last name of the member.</FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="memberEmail">Member Email</Label>
            <Input
              id="memberEmail"
              name="memberEmail"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.memberEmail}
              invalid={formik.touched.memberEmail && !!formik.errors.memberEmail}
            />
            <FormFeedback>{formik.errors.memberEmail}</FormFeedback>
            <FormText color="muted">Enter the email address of the member.</FormText>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerMemberDetailsStep;