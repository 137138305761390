import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, FormGroup, Input, Button, FormText, FormFeedback } from 'reactstrap';
import { useSearchUsers } from '../../core/socialgraph/hooks/useSearchUser';

const SearchComponent = () => {
  const [keyword, setKeyword] = useState('');
  const [searchError, setSearchError] = useState(null);

  // Obtener el userID del estado de Redux
  const userID = useSelector(state => state.Login.user.id);

  // Pasar userID a useSearchUsers
  const { users, search, removeUserAt } = useSearchUsers(userID);

  const handleSearch = async () => {
    setSearchError(null);
    try {
      await search(keyword);
    } catch (error) {
      setSearchError('Error searching users');
    }
  };

  return (
    <div className="search-component">
      <FormGroup>
        <Input
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search customer"
          className="search-input"
          invalid={!!searchError}
        />
        <FormFeedback>{searchError}</FormFeedback>
      </FormGroup>
      <Button onClick={handleSearch} color="primary">
        Search
      </Button>
      {users && (
        <ul className="user-list">
          {users.map((user, index) => (
            <li key={user.id} className="user-item">
              <span>{user.firstName} {user.lastName} ({user.email})</span>
              <Button onClick={() => removeUserAt(index)} color="danger" size="sm">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchComponent;