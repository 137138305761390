import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { fetchServicesRequest } from '../../store/masters/actions';

const actionMap = {
    services: fetchServicesRequest,
};

const selectorMap = {
    services: state => state.services.services,
};

const IMMasterMultiSelect = ({ collectionName, selectedValues, onChange, label }) => {
    const dispatch = useDispatch();
    const masterItems = useSelector(selectorMap[collectionName]);
    const loading = useSelector(state => state[collectionName].loading);
    const error = useSelector(state => state[collectionName].error);

    useEffect(() => {
        const action = actionMap[collectionName];
        if (action) {
            dispatch(action());
        }
    }, [dispatch, collectionName]);

    const options = masterItems.map(item => ({
        value: item.id,
        label: item.name,
    }));

    const selectedOptions = Array.isArray(selectedValues)
        ? selectedValues.map(value => options.find(option => option.value === value.id))
        : [];

    const handleChange = (selected) => {
        const selectedItems = selected ? selected.map(option => ({ id: option.value, label: option.label })) : [];
        onChange(selectedItems);
    };

    return (
        <div className="mb-3">
            <label className="control-label">{label}</label>
            <Select
                value={selectedOptions}
                onChange={handleChange}
                options={options}
                isMulti
                isLoading={loading}
                classNamePrefix="select2-selection"
                placeholder={`Select ${label.toLowerCase()}...`}
            />
            {error && <div className="text-danger">{error}</div>}
        </div>
    );
};

export default IMMasterMultiSelect;