import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Form, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, FormText } from 'reactstrap';
import {
  fetchSubcategoriesRequest,
  addSubcategoryRequest,
  updateSubcategoryRequest,
  deleteSubcategoryRequest,
} from '../../store/masters/actions';

const SubcategoriesManagement = ({ serviceId }) => {
  const dispatch = useDispatch();
  const subcategories = useSelector(state => state.masters.subcategories);
  const loading = useSelector(state => state.masters.loading);
  const error = useSelector(state => state.masters.error);

  const [modal, setModal] = useState(false);
  const [currentSubcategory, setCurrentSubcategory] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    console.log('serviceId immastersubcategories',serviceId)
    dispatch(fetchSubcategoriesRequest(serviceId));
  }, [dispatch, serviceId]);

  const toggleModal = () => setModal(!modal);

  const handleAdd = () => {
    setCurrentSubcategory({});
    setIsEditing(false);
    toggleModal();
  };

  const handleEdit = (subcategory) => {
    setCurrentSubcategory(subcategory);
    setIsEditing(true);
    toggleModal();
  };

  const handleDelete = (subcategoryId) => {
    dispatch(deleteSubcategoryRequest(serviceId, subcategoryId));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('currentSubcategory',currentSubcategory,serviceId)
    if (isEditing) {
      dispatch(updateSubcategoryRequest(serviceId, currentSubcategory.id, currentSubcategory));
    } else {
      dispatch(addSubcategoryRequest(serviceId, currentSubcategory));
    }
    toggleModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentSubcategory(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <div>
      <h2>Subcategories for Service ID: {serviceId}</h2>
      <Button color="primary" onClick={handleAdd}>Add New Subcategory</Button>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subcategories.map(subcategory => (
            <tr key={subcategory.id}>
              <td>{subcategory.name}</td>
              <td>{subcategory.description}</td>
              <td>
                <Button color="warning" onClick={() => handleEdit(subcategory)}>Edit</Button>
                <Button color="danger" onClick={() => handleDelete(subcategory.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={modal} toggle={toggleModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{isEditing ? 'Edit Subcategory' : 'Add Subcategory'}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                name="name"
                value={currentSubcategory.name || ''}
                onChange={handleChange}
                placeholder="Enter name"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="description">Description</Label>
              <Input
                name="description"
                value={currentSubcategory.description || ''}
                onChange={handleChange}
                placeholder="Enter description"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">{isEditing ? 'Update' : 'Add'}</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default SubcategoriesManagement;