import React from "react";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Utility
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Admin
import MasterRole from "../pages/MasterTables/Status";
import MasterServices from "../pages/MasterTables/services";
import MasterServicesManager from "../pages/MasterTables/ServiceManager";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import {VehiclesListView,AddNewVehicleView,UpdateVehicleView,DetailedVehicleView} from "../pages/vehicles"
import {MembersListView, AddNewMemberView,UpdateMemberView} from "../pages/Members"
import {PartnersListView,AddNewPartnerView,UpdatePartnerView} from "../pages/Partners"
import {CustomersListView,AddNewCustomerView,UpdateCustomerView} from "../pages/customers"
import WizardBusiness from "../pages/Business/WizardBusiness";


//landings

// import Layout7 from "../pages/Landing/Layout7/Layout7";



const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // //Admin
  { path: "/admin/status", component: <MasterRole /> },
  { path: "/admin/services", component: <MasterServices /> },
  { path: "/admin/servicesManager", component: <MasterServicesManager /> },

  // //Partners
{ path: "/partners", component: <PartnersListView/> },
{ path: "/partner/add", component: <AddNewPartnerView/> },
{ path: "/partner/:partnerId/update", component : <UpdatePartnerView/>},
// { path: "/vehicle/:vendorID/:vehicleId/view", component : <DetailedPartnerView/>

 // //Business
 { path: "/business/new", component: <WizardBusiness/> },

// //vehicle
{ path: "/vehicles", component: <VehiclesListView/> },
{ path: "/vehicle/add", component: <AddNewVehicleView/> },
{ path: "/vehicle/:vendorID/:vehicleId/update", component : <UpdateVehicleView/>},
// { path: "/vehicle/:vendorID/:vehicleId/view", component : <DetailedVehicleView/>

// //Members
{ path: "/members", component: <MembersListView/> },
{ path: "/member/add", component: <AddNewMemberView/> },
{ path: "/member/:vendorID/:memberId/update", component : <UpdateMemberView/>},
// { path: "/member/:vendorID/:memberId/view", component : <DetailedMemberView/>

// //vehicle
{ path: "/customers", component: <CustomersListView/> },
{ path: "/customer/add", component: <AddNewCustomerView/> },
{ path: "/customer/:vendorID/:customerId/update", component : <UpdateCustomerView/>},
// { path: "/vehicle/:vendorID/:vehicleId/view", component : <DetailedVehicleView/>

// { path: "/Layout7", component: <Layout7/> },

// this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  // { path: "/Layout7", component: <Layout7/> },
];

export { userRoutes, authRoutes };
