import React from 'react';
import { Row, Col, FormGroup, FormFeedback, FormText, Label } from 'reactstrap';
import { useFormikContext } from 'formik';
import IMRolesSelectComponent from "../../components/ui/IMRoleSelectorComponent";
import IMPhoto from '../../components/ui/IMPhoto';

const MemberRolesPhotoStep = () => {
  const formik = useFormikContext();

  return (
    <div>
      <h5>Member Roles & Photo</h5>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="roles">Roles</Label>
            <IMRolesSelectComponent
              selectedRoles={formik.values.memberRoles}
              onChange={(value) => formik.setFieldValue("memberRoles", value)}
            />
            <FormFeedback>{formik.errors.memberRoles}</FormFeedback>
            <FormText color="muted">Select one or more roles for the member.</FormText>
          </FormGroup>
        </Col>
        <Col md="6">
          <IMPhoto
            currentPhoto={formik.values.memberPhoto}
            onUpload={(url) => formik.setFieldValue("memberPhoto", url)}
            fieldName="memberPhoto"
            formik={formik}
          />
        </Col>
      </Row>
    </div>
  );
};

export default MemberRolesPhotoStep;