import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { fetchServicesRequest, fetchStatusRequest } from '../../store/masters/actions';

const actionMap = {
    services: fetchServicesRequest,
    status: fetchStatusRequest,
};

const selectorMap = {
    services: state => state.services.services,
    status: state => state.status.status,
};

const IMMasterDropdown = ({ collectionName, selectedValue, onChange, label }) => {
    const dispatch = useDispatch();
    const masterItems = useSelector(selectorMap[collectionName]);
    const loading = useSelector(state => state[collectionName].loading);
    const error = useSelector(state => state[collectionName].error);

    useEffect(() => {
        const action = actionMap[collectionName];
        if (action) {
            dispatch(action());
        }
    }, [dispatch, collectionName]);

    const options = masterItems.map(item => ({
        value: item.id,
        label: item.name,
    }));

    const selectedOption = options.find(option => option.label === selectedValue);

    return (
        <div className="mb-3">
            <label className="control-label">{label}</label>
            <Select
                value={selectedOption}
                onChange={onChange}
                options={options}
                isLoading={loading}
                classNamePrefix="select2-selection"
                placeholder={`Select ${label.toLowerCase()}...`}
            />
            {error && <div className="text-danger">{error}</div>}
        </div>
    );
};

export default IMMasterDropdown;