import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPartner, fetchPartnersRequest } from '../../store/partners/actions'; // Ajusta la ruta según tu estructura de carpetas

const IMPartnerSelector = ({ id }) => {
  const dispatch = useDispatch();
  const selectedPartner = useSelector(state => state.partners.selectedPartner);
  const partners = useSelector(state => state.partners.partners);

  useEffect(() => {
    dispatch(fetchPartnersRequest());
  }, [dispatch]);

  const handleChange = (event) => {
    const partner = partners.find(partner => partner.id === event.target.value);
    dispatch(setSelectedPartner(partner));
  };

  return (
    <select id={id} className="form-select" onChange={handleChange} value={selectedPartner?.id || ''}>
      <option value="">Select Partner</option>
      {partners.map(partner => (
        <option key={partner.id} value={partner.id}>{partner.title}</option>
      ))}
    </select>
  );
};

export default IMPartnerSelector;