import PropTypes from 'prop-types';
import React from "react";
import { useLocation } from "react-router-dom";

const NonAuthLayout = ({ children }) => {
  const location = useLocation();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  React.useEffect(() => {
    const currentPage = capitalizeFirstLetter(location.pathname);
    document.title = `${currentPage} | LogiTruck - Admin & Dashboard`;
  }, [location]);

  return <React.Fragment>{children}</React.Fragment>;
};

NonAuthLayout.propTypes = {
  children: PropTypes.any
};

export default NonAuthLayout;