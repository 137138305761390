import {
  FETCH_MASTER_ITEMS_REQUEST,
  FETCH_MASTER_ITEMS_SUCCESS,
  FETCH_MASTER_ITEMS_FAILURE,
  ADD_MASTER_ITEM_REQUEST,
  ADD_MASTER_ITEM_SUCCESS,
  ADD_MASTER_ITEM_FAILURE,
  UPDATE_MASTER_ITEM_REQUEST,
  UPDATE_MASTER_ITEM_SUCCESS,
  UPDATE_MASTER_ITEM_FAILURE,
  DELETE_MASTER_ITEM_REQUEST,
  DELETE_MASTER_ITEM_SUCCESS,
  DELETE_MASTER_ITEM_FAILURE,
  FETCH_SUBCATEGORIES_REQUEST,
  FETCH_SUBCATEGORIES_SUCCESS,
  FETCH_SUBCATEGORIES_FAILURE,
  ADD_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_SUCCESS,
} from './types';

const initialState = {
  subcategories: [],
  collections: {},
  loading: false,
  error: null,
};

const mastersReducer = (state = initialState, action) => {
  switch (action.type) {

    case ADD_MASTER_ITEM_REQUEST:
    case UPDATE_MASTER_ITEM_REQUEST:
    case DELETE_MASTER_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case FETCH_MASTER_ITEMS_REQUEST:
        return {
          ...state,
          loading: { ...state.loading, [action.payload]: true },
          error: { ...state.error, [action.payload]: null },
        };
      
      case FETCH_MASTER_ITEMS_SUCCESS:
        console.log("Reducir FETCH_MASTER_ITEMS_SUCCESS con", action.payload); // Añade este log
        return {
          ...state,
          collections: {
            ...state.collections,
            [action.payload.collectionName]: action.payload.items,
          },
          loading: { ...state.loading, [action.payload.collectionName]: false },
          error: { ...state.error, [action.payload.collectionName]: null },
        };
        case FETCH_MASTER_ITEMS_FAILURE:
          return {
            ...state,
            loading: { ...state.loading, [action.payload.collectionName]: false },
            error: { ...state.error, [action.payload.collectionName]: action.payload.error },
          };
    case ADD_MASTER_ITEM_SUCCESS:
      return {
        ...state,
        [action.payload.collectionName]: [
          ...state[action.payload.collectionName],
          action.payload.item,
        ],
        loading: false,
      };
    case UPDATE_MASTER_ITEM_SUCCESS:
      return {
        ...state,
        [action.payload.collectionName]: state[action.payload.collectionName].map(item =>
          item.id === action.payload.item.id ? action.payload.item : item
        ),
        loading: false,
      };
    case DELETE_MASTER_ITEM_SUCCESS:
      return {
        ...state,
        [action.payload.collectionName]: state[action.payload.collectionName].filter(
          item => item.id !== action.payload.itemId
        ),
        loading: false,
      };
    case ADD_MASTER_ITEM_FAILURE:
    case UPDATE_MASTER_ITEM_FAILURE:
    case DELETE_MASTER_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      case FETCH_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        subcategories: action.payload,
      };
    case FETCH_SUBCATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategories: [...state.subcategories, action.payload],
      };
    case UPDATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategories: state.subcategories.map(subcategory =>
          subcategory.id === action.payload.id ? action.payload : subcategory
        ),
      };
    case DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategories: state.subcategories.filter(subcategory => subcategory.id !== action.payload),
      };
    default:
      return state;
  }
};

export default mastersReducer;


   
    