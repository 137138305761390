import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_ROLES_REQUEST, FETCH_ROLES_SUCCESS, FETCH_ROLES_FAILURE } from './actionTypes';
import { fetchRolesSuccess, fetchRolesFailure } from './actions';
import { get } from '../../helpers/api_helper';

function* fetchRolesSaga(action) {
  try {
    const response = yield call(get, `roles/?search=${action.payload}`);
    yield put(fetchRolesSuccess(response));
  } catch (error) {
    yield put(fetchRolesFailure(error.message));
  }
}

export function* watchFetchRoles() {
  yield takeLatest(FETCH_ROLES_REQUEST, fetchRolesSaga);
}