// services/saga.js
import { takeLatest, call, put } from 'redux-saga/effects';
import { FETCH_SERVICES_REQUEST, FETCH_SERVICES_SUCCESS, FETCH_SERVICES_FAILURE } from '../types';
import { getMasterItems }  from '../../../helpers/firebase_mastesTables_helper';

function* fetchServicesSaga() {
  try {
    const services = yield call(getMasterItems, 'services');
    yield put({ type: FETCH_SERVICES_SUCCESS, payload: services });
  } catch (error) {
    yield put({ type: FETCH_SERVICES_FAILURE, payload: error.message });
  }
}

export default function* servicesSaga() {
  yield takeLatest(FETCH_SERVICES_REQUEST, fetchServicesSaga);
}

