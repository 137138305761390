import { functions } from '../../helpers/firebase'; // Importamos desde firebase.js

export const searchUsers = async (userID, keyword, page = 0, size = 100) => {
  const instance = functions.httpsCallable('searchUsers');
  try {
    const res = await instance({
      userID,
      keyword,
      page,
      size,
    });

    return res?.data?.users;
  } catch (error) {
    console.log(error);
    return null;
  }
};