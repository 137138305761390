import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  FormText,
  FormGroup,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { fetchMemberRequest, updateMemberRequest } from "../../store/members/actions";
import IMRolesSelectComponent from "../../components/ui/IMRoleSelectorComponent";
import IMPartnerSelector from "../../components/ui/IMPartnerSelector";
import IMMasterDropdown from "components/ui/IMMasterDropdown";
import { useParams, useNavigate } from "react-router-dom";
import IMPhoto from "../../components/ui/IMPhoto";

const UpdateMember = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorID, memberId } = useParams();
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    status: '',
    photo: '',
    memberId: memberId, // Agregamos el memberId a los valores iniciales
  });
  const [imageURL, setImageURL] = useState("");
  const { selectedMember, loading, error } = useSelector((state) => state.members);
  const selectedPartner = useSelector((state) => state.partners.selectedPartner);

  useEffect(() => {
    if (vendorID && memberId) {
      dispatch(fetchMemberRequest(vendorID, memberId));
    }
  }, [dispatch, vendorID, memberId]);

  useEffect(() => {
    if (selectedMember) {
      console.log('selectedMember',selectedMember)
      setInitialValues({
        firstName: selectedMember.firstName || '',
        lastName: selectedMember.lastName || '',
        email: selectedMember.email || '',
        roles: selectedMember.roles || [],
        status: selectedMember.status || '',
        photo: selectedMember.photo || '',
        userID: selectedMember.id || memberId, // Aseguramos que el memberId esté presente
      });
      setImageURL(selectedMember.photo || "");
    }
  }, [selectedMember]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please Enter First Name"),
    lastName: Yup.string().required("Please Enter Last Name"),
    email: Yup.string().email("Invalid email format").required("Please Enter Email"),
    roles: Yup.array().min(1, "Select at least one role").required("Please Select Roles"),
    status: Yup.string().required("Please Select Status"),
  });

  const handleSubmit = (values) => {
    const memberData = {
      ...values,
      vendorID: selectedPartner.id,
      photo: values.photo ||  "",
    };
    console.log('memberData',memberData)
    dispatch(updateMemberRequest(memberData, navigate));
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Members" breadcrumbItem="Update Member" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <Formik
                key={initialValues.memberId} // Utiliza el memberId como clave única para forzar el remount cuando initialValues cambian
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="firstName">First Name</Label>
                          <Field
                            name="firstName"
                            as={Input}
                            invalid={formik.touched.firstName && !!formik.errors.firstName}
                          />
                          <FormFeedback>{formik.errors.firstName}</FormFeedback>
                          <FormText color="muted">Enter the first name of the member.</FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="lastName">Last Name</Label>
                          <Field
                            name="lastName"
                            as={Input}
                            invalid={formik.touched.lastName && !!formik.errors.lastName}
                          />
                          <FormFeedback>{formik.errors.lastName}</FormFeedback>
                          <FormText color="muted">Enter the last name of the member.</FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <Field
                            name="email"
                            type="email"
                            as={Input}
                            invalid={formik.touched.email && !!formik.errors.email}
                          />
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                          <FormText color="muted">Enter the email address of the member.</FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="roles">Roles</Label>
                          <IMRolesSelectComponent
                            selectedRoles={formik.values.roles}
                            onChange={value => formik.setFieldValue("roles", value)}
                          />
                          <FormFeedback>{formik.errors.roles}</FormFeedback>
                          <FormText color="muted">Select one or more roles for the member.</FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                        <IMMasterDropdown
                            collectionName="status"
                            selectedValue={formik.values.status}
                            onChange={(option) => formik.setFieldValue("status", option.label)}
                            label="Status"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        {selectedPartner ? (
                          <FormGroup>
                            <Label htmlFor="company">Partner</Label>
                            <Input
                              id="company"
                              name="company"
                              type="text"
                              value={selectedPartner.title}
                              disabled
                            />
                          </FormGroup>
                        ) : (
                          <IMPartnerSelector />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <IMPhoto
                          formik={formik}
                          fieldName="photo"
                          currentPhoto={imageURL}
                          onUpload={(url) => formik.setFieldValue("photo", url)}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? "Loading..." : "Update"}
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      className="ml-2"
                      onClick={() => navigate("/members")}
                    >
                      Back to Member List
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateMember;