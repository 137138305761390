import React, { useEffect, useRef, useState } from 'react';
import { Input, FormGroup, Label, FormFeedback } from 'reactstrap';

const loadScript = (url, callback) => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    script.id = 'googleMaps';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const IMLocation = ({ label, selectedLocation, onLocationSelect, formik }) => {
  const [location, setLocation] = useState(selectedLocation || {});
  const [locationError, setLocationError] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const googleMapsApiKey = "AIzaSyAdtLNw-8dr4V2zM6x7cCN6AzxZboqPUQk";
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`,
      () => {
        if (inputRef.current) {
          const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, { types: ['geocode'] });
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
              const newLocation = {
                label: place.formatted_address,
                location: place.geometry.location.toJSON(),
              };
              setLocation(newLocation);
              setLocationError(false);
              onLocationSelect(newLocation);
              formik.setFieldValue('location', newLocation);
            }
          });
          // Ajustar el z-index del contenedor de autocompletado
          const autocompleteContainer = document.getElementsByClassName('pac-container')[0];
          if (autocompleteContainer) {
            autocompleteContainer.style.zIndex = '1050';
          }
        }
      }
    );
  }, []);

  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input
        innerRef={inputRef}
        type="text"
        value={location.label || ""}
        onChange={(e) => setLocation({ ...location, label: e.target.value })}
        onBlur={() => {
          if (!location.label) setLocationError(true);
        }}
        invalid={locationError}
        placeholder="Start typing an address"
      />
      <FormFeedback>{locationError && "Please select a location"}</FormFeedback>
    </FormGroup>
  );
};

export default IMLocation;