import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { fetchMembersRequest } from '../../store/members/actions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Label, Button } from 'reactstrap';
import IMPartnerSelector from "../../components/ui/IMPartnerSelector";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import IMRolesSelectComponent from "../../components/ui/IMRoleSelectorComponent";
import { useNavigate } from 'react-router-dom';

const MembersListView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const members = useSelector(state => state.members.members);
  const loading = useSelector(state => state.members.loading);
  const selectedPartner = useSelector(state => state.partners.selectedPartner);
  const [userRole, setUserRole] = useState('Admin');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const baseAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
  useEffect(() => {
    if (selectedPartner) {
      dispatch(fetchMembersRequest(selectedPartner.id, userRole));
    }
  }, [dispatch, selectedPartner, userRole]);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleUserRoleChange = (role) => {
    setUserRole(role);
  };

  const handleReview = (vendorID, userID) => {
    navigate(`/member/${vendorID}/${userID}/view`);
  };

  const handleEdit = (vendorID, userID) => {
    navigate(`/member/${vendorID}/${userID}/update`);
  };

  const handleDelete = async (vendorID, userID) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      const url = `${baseAPIURL}members/${vendorID}/${userID}`;
      console.log('url delete ', url);
      const response = await fetch(url, {
        method: "DELETE",
      });
      window.location.reload(false);
    }
  };

  const handleRolesChange = (roles) => {
    setSelectedRoles(roles);
  };

  const getStatusBadge = (status) => {
    const statusMap = {
      Active: 'success',
      Inactive: 'danger',
    };
    return <span className={`badge bg-${statusMap[status] || 'secondary'}`}>{status}</span>;
  };

  const filteredMembers = Array.isArray(members?.users) ? members.users.filter(user => {
    if (selectedRoles.length === 0) return true;
    const userRoles = user.roles.map(role => role.role);
    return selectedRoles.some(role => userRoles.includes(role.role));
  }) : [];

  const data = {
    columns: [
      {
        label: 'Photo',
        field: 'photo',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'First Name',
        field: 'firstName',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Last Name',
        field: 'lastName',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Roles',
        field: 'roles',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 200,
      },
    ],
    rows: filteredMembers.map(user => ({
      ...user,
      photo: user.photo 
        ? <img src={user.photo} alt="Member" style={{ width: '30px', height: '30px', borderRadius: '30%', objectFit: 'cover', verticalAlign: 'middle' }} /> 
        : <i className="mdi mdi-account-circle" style={{ fontSize: '30px', color: '#adb5bd', verticalAlign: 'middle' }}></i>,
      roles: user.roles.map(role => role.role).join(', '),
      status: getStatusBadge(user.status),
      actions: (
        <div>
          <Button color="info" size="sm" className="me-2" onClick={() => handleReview(user.vendorID, user.userID)}>Review</Button>
          <Button color="warning" size="sm" className="me-2" onClick={() => handleEdit(user.vendorID, user.userID)}>Edit</Button>
          <Button color="danger" size="sm" onClick={() => handleDelete(user.vendorID, user.userID)}>Delete</Button>
        </div>
      ),
    }))
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Home" breadcrumbItem="Admin Members" addRoute="/members/add" />
       
        <Row className="mb-4 d-flex align-items-center justify-content-between">
          <Col md={4} className="d-flex align-items-center">
            <Label for="partnerSelector" className="me-2">Partner:</Label>
            <IMPartnerSelector id="partnerSelector" />
          </Col>
          <Col md={4} className="d-flex align-items-center">
            <IMRolesSelectComponent selectedRoles={selectedRoles} onChange={handleRolesChange} />
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <Button color="primary" onClick={() => navigate("/member/add")}>
              <i className="mdi mdi-plus me-2"></i> Add New
            </Button>
          </Col>
        </Row>
        <div className="members-list">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <MDBDataTable
              striped
              bordered
              small
              data={data}
              sortable
              searching={true}
              paging={true}
              responsive
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MembersListView;