import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, GET_USER_REQUEST, GET_ERRORS } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess, setCurrentUser } from "./actions";
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { get } from '../../../helpers/api_helper';
import setAuthToken from '../../../helpers/setAuthToken';
import jwt_decode from "jwt-decode";

const fireBaseBackend = getFirebaseBackend();

function* getUserSaga(action) {
  try {
    const { userId } = action.payload;
    const response = yield call(get, `/user/${userId}`);
    yield put(setCurrentUser(response));
  } catch (error) {
    yield put({ type: GET_ERRORS, payload: "Failed to fetch user data" });
  }
}

export function* watchGetUser() {
  yield takeEvery(GET_USER_REQUEST, getUserSaga);
}

function* loginUser({ payload: { user, navigate } }) {
  try {
    const token = yield call(fireBaseBackend.loginUser, user.email, user.password);
    console.log(' token new  web login',token)
    localStorage.setItem("jwtToken", token); // Almacenar el token en localStorage
    setAuthToken(token); // Configurar el token para futuras solicitudes
    const decoded = jwt_decode(token.replace('Bearer ', '')); // Decodificar el token sin el prefijo "Bearer"
    console.log('decoded',decoded)
    
    const userId = decoded.id;
    console.log('decoded user',decoded.id)
    const userResponse = yield call(get, `/user/${userId}`);
    console.log('userResponse',userResponse)
    yield put(setCurrentUser(userResponse));
    yield put(loginSuccess(userResponse));
    console.log ("/dashboard")
    navigate("/dashboard");
  } catch (error) {
    console.log('error login',error)
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { navigate } }) {
  try {
    yield call(fireBaseBackend.logout);
    localStorage.removeItem("authUser");
    localStorage.removeItem("jwtToken");
    setAuthToken(null); // Eliminar el token de las cabeceras
    yield put(logoutUserSuccess());
    navigate("/login");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* socialLogin({ payload: { data, navigate, type } }) {
  try {
    const token = yield call(fireBaseBackend.socialLoginUser, data, type);
    localStorage.setItem("jwtToken", token); // Almacenar el token en localStorage
    setAuthToken(token); // Configurar el token para futuras solicitudes
    const decoded = jwt_decode(token.replace('Bearer ', '')); // Decodificar el token sin el prefijo "Bearer"
    const userResponse = yield call(get, `/user/${decoded.id}`);
    yield put(setCurrentUser(userResponse));
    yield put(loginSuccess(userResponse));
    navigate("/dashboard");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_USER_REQUEST, getUserSaga);
}

export default authSaga;