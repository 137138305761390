import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  FETCH_MASTER_ITEMS_REQUEST,
  ADD_MASTER_ITEM_REQUEST,
  UPDATE_MASTER_ITEM_REQUEST,
  DELETE_MASTER_ITEM_REQUEST,
  FETCH_SUBCATEGORIES_REQUEST,
  ADD_SUBCATEGORY_REQUEST,
  UPDATE_SUBCATEGORY_REQUEST,
  DELETE_SUBCATEGORY_REQUEST,
} from "./types"
import {
  fetchMasterItemsSuccess,
  fetchMasterItemsFailure,
  addMasterItemSuccess,
  addMasterItemFailure,
  updateMasterItemSuccess,
  updateMasterItemFailure,
  deleteMasterItemSuccess,
  deleteMasterItemFailure,
  fetchSubcategoriesSuccess,
  fetchSubcategoriesFailure,
  addSubcategorySuccess,
  addSubcategoryFailure,
  updateSubcategorySuccess,
  updateSubcategoryFailure,
  deleteSubcategorySuccess,
  deleteSubcategoryFailure,
} from './actions';
import {
  getMasterItems,
  addMasterItem,
  updateMasterItem,
  deleteMasterItem,
  getSubcategoriesByService,
  addSubcategoryToService,
  updateSubcategoryInService,
  deleteSubcategoryFromService,
} from "../../helpers/firebase_mastesTables_helper"


// saga.js
function* fetchMasterItems(action) {
  try {
    console.log("Saga fetchMasterItems ejecutada con", action.payload); // Añade este log
    const items = yield call(getMasterItems, action.payload);
    console.log("Items recibidos:", items); // Añade este log
    yield put(fetchMasterItemsSuccess(action.payload, items));
  } catch (error) {
    yield put(fetchMasterItemsFailure(action.payload, error.message));
  }
}

export function* watchFetchMasterItems() {
  yield takeLatest(FETCH_MASTER_ITEMS_REQUEST, fetchMasterItems);
}

function* addMasterItemSaga(action) {
  try {
    const item = yield call(addMasterItem, action.payload.collectionName, action.payload.item);
    yield put(addMasterItemSuccess(action.payload.collectionName, item));
  } catch (error) {
    yield put(addMasterItemFailure(action.payload.collectionName, error.message));
  }
}

function* updateMasterItemSaga(action) {
  try {
    const item = yield call(updateMasterItem, action.payload.collectionName, action.payload.itemId, action.payload.updatedItem);
    yield put(updateMasterItemSuccess(action.payload.collectionName, item));
  } catch (error) {
    yield put(updateMasterItemFailure(action.payload.collectionName, error.message));
  }
}

function* deleteMasterItemSaga(action) {
  try {
    yield call(deleteMasterItem, action.payload.collectionName, action.payload.itemId);
    yield put(deleteMasterItemSuccess(action.payload.collectionName, action.payload.itemId));
  } catch (error) {
    yield put(deleteMasterItemFailure(action.payload.collectionName, error.message));
  }
}


// Fetch subcategories
function* fetchSubcategoriesSaga(action) {
  try {
    console.log('action.payload.serviceId',action.payload.serviceId)
    const subcategories = yield call(getSubcategoriesByService, action.payload.serviceId);
    yield put(fetchSubcategoriesSuccess(subcategories));
  } catch (error) {
    yield put(fetchSubcategoriesFailure(error.message));
  }
}

// Add subcategory
function* addSubcategorySaga(action) {
  try {
    console.log('addSubcategorySaga',addSubcategorySaga)
    const subcategory = yield call(addSubcategoryToService, action.payload.serviceId, action.payload.subcategory);
    yield put(addSubcategorySuccess(subcategory));
  } catch (error) {
    yield put(addSubcategoryFailure(error.message));
  }
}

// Update subcategory
function* updateSubcategorySaga(action) {
  try {
    const subcategory = yield call(updateSubcategoryInService, action.payload.serviceId, action.payload.subcategoryId, action.payload.updatedSubcategory);
    yield put(updateSubcategorySuccess(subcategory));
  } catch (error) {
    yield put(updateSubcategoryFailure(error.message));
  }
}

// Delete subcategory
function* deleteSubcategorySaga(action) {
  try {
    yield call(deleteSubcategoryFromService, action.payload.serviceId, action.payload.subcategoryId);
    yield put(deleteSubcategorySuccess(action.payload.subcategoryId));
  } catch (error) {
    yield put(deleteSubcategoryFailure(error.message));
  }
}


export function* watchFetchSubcategories() {
  yield takeLatest(FETCH_SUBCATEGORIES_REQUEST, fetchSubcategoriesSaga);
}
export function* watchAddSubCategories() {
  yield takeLatest(ADD_SUBCATEGORY_REQUEST, addSubcategorySaga);
}
export function* watchUpdateSubCategories() {
  yield takeLatest(UPDATE_SUBCATEGORY_REQUEST, updateSubcategorySaga);
}
export function* watchDeleteSubCategories() {
  yield takeLatest(DELETE_SUBCATEGORY_REQUEST, deleteSubcategorySaga);
}


export function* watchAddMasterItem() {
  yield takeLatest(ADD_MASTER_ITEM_REQUEST, addMasterItemSaga);
}

export function* watchUpdateMasterItem() {
  yield takeLatest(UPDATE_MASTER_ITEM_REQUEST, updateMasterItemSaga);
}

export function* watchDeleteMasterItem() {
  yield takeLatest(DELETE_MASTER_ITEM_REQUEST, deleteMasterItemSaga);
}

export default function* mastersSaga() {
  yield all([
    watchFetchMasterItems(),
    watchAddMasterItem(),
    watchUpdateMasterItem(),
    watchDeleteMasterItem(),
    watchFetchSubcategories(),
    watchAddSubCategories(),
    watchUpdateSubCategories(),
    watchDeleteSubCategories(),
  ]);
}