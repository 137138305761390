import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { fetchPartnersRequest } from '../../store/partners/actions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Label, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb"; // Asegúrate de que esta ruta sea correcta
import { useNavigate } from 'react-router-dom';

const PartnersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const partners = useSelector(state => state.partners.partners);
  const loading = useSelector(state => state.partners.loading);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const baseAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
console.log ('partners', partners)
  useEffect(() => {
    dispatch(fetchPartnersRequest());
  }, [dispatch]);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleReview = (vendorID) => {
    navigate(`/partner/${vendorID}/view`);
  };

  const handleEdit = (vendorID, partnerID) => {
    console.log('vendorID',vendorID)
    navigate(`/partner/${vendorID}/update`);
  };

  const handleDelete = async (vendorID) => {
    if (window.confirm("Are you sure you want to delete this item?" + vendorID)) {
      const url = `${baseAPIURL}company/${vendorID}`;
      console.log('url delete ', url);
      const response = await fetch(url, {
        method: "DELETE",
      });
      if (response.ok) {
        window.location.reload(false);
      }
    }
  };

  const getStatusBadge = (status) => {
    const statusMap = {
      Active: 'success',
      Inactive: 'danger',
    };
    return <span className={`badge bg-${statusMap[status] || 'secondary'}`}>{status}</span>;
  };

  const data = {
    columns: [
      {
        label: 'Photo',
        field: 'photo',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Partner Name',
        field: 'title',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Services',
        field: 'services',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 200,
      },
    ],
    rows: Array.isArray(partners) ? partners.map(partner => ({
      ...partner,
      photo: partner.photo ? (
        <img src={partner.photo} alt="partner" style={{ width: '50px', height: '50px' }} />
      ) : (
        <i className="mdi mdi-account-circle" style={{ fontSize: '30px', color: '#adb5bd', verticalAlign: 'middle' }}></i>
      ),
      services: partner.services?.map(service => service.label).join(", "), // Assuming services is an array of objects with a `name` property

      status: getStatusBadge(partner.status),
      actions: (
        <div>
          <Button color="info" size="sm" className="me-2" onClick={() => handleReview(partner.id )}>Review</Button>
          <Button color="warning" size="sm" className="me-2" onClick={() => handleEdit(partner.id)}>Edit</Button>
          <Button color="danger" size="sm" onClick={() => handleDelete(partner.id)}>Delete</Button>
        </div>
      ),
    })) : []
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Home" breadcrumbItem="Admin Partners" addRoute="/partner/add" />
        <Row className="mb-4 d-flex align-items-center justify-content-between">
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <Button color="primary" onClick={() => navigate("/partner/add")}>
              <i className="mdi mdi-plus me-2"></i> Add New
            </Button>
          </Col>
        </Row>
        <div className="partners-list">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <MDBDataTable
              striped
              bordered
              small
              data={data}
              sortable
              searching={true}
              paging={true}
              responsive
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PartnersList;