import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga , {watchGetUser} from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import { watchFetchPartners , watchAddPartner,watchFetchPartner,watchUpdatePartners} from "./partners/saga"; // Importar el saga de company
import { watchFetchVehicles , watchAddVehicle, watchFetchVehicle,watchUpdateVehicle} from './vehicles/saga';
import { watchFetchMembers , watchAddMember, watchFetchMember,watchUpdateMember} from './members/saga';
import { watchFetchRoles} from './roles/saga';
import masterSaga from './masters/saga';
import servicesSaga from './masters/services/saga';
import statusSaga from "./masters/status/saga"
import customerSaga from "./customers/saga"



export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(calendarSaga),
    fork(masterSaga),
    fork(servicesSaga),
    fork(statusSaga),
    fork(customerSaga),
    watchGetUser(),
    watchFetchPartners(),
    watchAddPartner(),
    watchFetchVehicles(),
    watchAddVehicle(),
    watchFetchVehicle(),
    watchUpdateVehicle(),
    watchFetchMembers() , 
    watchAddMember(), 
    watchFetchMember(),
    watchUpdateMember(),
    watchFetchRoles(),
    watchFetchPartner(),
    watchUpdatePartners(),
   
  ])
}
