import { call, all, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_VEHICLES_REQUEST,
  ADD_VEHICLE_REQUEST,
  UPDATE_VEHICLE_REQUEST,
  FETCH_VEHICLE_REQUEST,
} from "./actionTypes"
import {
  fetchVehiclesSuccess,
  fetchVehiclesFailure,
  addVehicleFailure,
  addVehicleSuccess,
  updateVehicleSuccess,
  updateVehicleFailure,
  fetchVehicleSuccess,
  fetchVehicleFailure,
} from "./actions"
import { get, post, put as apiPut } from '../../helpers/api_helper'; // Renombrar 'put' a 'apiPut'

function* fetchVehicles(action) {
  try {
    const { companyId, vehicleType } = action.payload;
    const response = yield call(get, `/vehicles?vendorID=${companyId}&type=${vehicleType}`);
    yield put(fetchVehiclesSuccess(response));
  } catch (error) {
    yield put(fetchVehiclesFailure(error.message));
  }
}

function* fetchVehicle(action) {
  try {
    const { vendorID, vehicleId } = action.payload;
    const url = `/vehicles/${vendorID}/${vehicleId}`;
    const response = yield call(get, url);
    yield put(fetchVehicleSuccess(response));
  } catch (error) {
    yield put(fetchVehicleFailure(error.message));
  }
}

function* addVehicle(action) {
  try {
    const response = yield call(post, '/vehicles/add', action.payload.vehicle);
    yield put(addVehicleSuccess(response));
    action.payload.navigate("/vehicles");
  } catch (error) {
    yield put(addVehicleFailure(error.message));
    action.payload.navigate("/vehicles"); // O manejar el error de otra forma
  }
}

function* updateVehicle(action) {
  try {
    // const response = await fetch(`${baseAPIURL}vehicles/${vendorID}/${vehicleId}`, {
      const { vehicle, navigate } = action.payload;
    const url = `vehicles/${vehicle.vendorID}/${vehicle.id}` 
    console.log("updateVehicle url" , url, vehicle) ;
    const response = yield call(apiPut, url, action.payload.vehicle);
    yield put(updateVehicleSuccess(response));
    action.payload.navigate("/vehicles")
  } catch (error) {
    console.log('error.message',error.message)
    yield put(updateVehicleFailure(error.message));
    action.payload.navigate("/vehicles");
  }
}

export function* watchFetchVehicles() {
  yield takeLatest(FETCH_VEHICLES_REQUEST, fetchVehicles);
}

export function* watchFetchVehicle() {
  yield takeLatest(FETCH_VEHICLE_REQUEST, fetchVehicle);
}

export function* watchAddVehicle() {
  yield takeLatest(ADD_VEHICLE_REQUEST, addVehicle);
}

export function* watchUpdateVehicle() {
  yield takeLatest(UPDATE_VEHICLE_REQUEST, updateVehicle);
}

export default function* vehicleSaga() {
  yield all([
    watchFetchVehicles(),
    watchFetchVehicle(),
    watchAddVehicle(),
    watchUpdateVehicle(),
  ]);
}