import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VerticalLayout from "components/VerticalLayout";
import HorizontalLayout from "components/HorizontalLayout";

// constants
import { layoutTypes } from "../../constants/layout";

const Authmiddleware = ({ children }) => {
  const layoutType = useSelector(state => state.Layout.layoutType);
  const isAuthenticated = useSelector(state => state.Login.isAuthenticated);

  const getLayout = (layoutType) => {
    return layoutType === layoutTypes.HORIZONTAL ? HorizontalLayout : VerticalLayout;
  };

  const Layout = getLayout(layoutType);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <React.Fragment>
      <Layout>{children}</Layout>
    </React.Fragment>
  );
};

export default Authmiddleware;