import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { fetchCustomersRequest } from '../../store/customers/actions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Label, Button } from 'reactstrap';
import PartnerSelector from "../../components/ui/IMPartnerSelector";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';

const CustomerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customers = useSelector(state => state.customers.customers); // Asegúrate de que estás obteniendo la data correcta
  const loading = useSelector(state => state.customers.loading);
  const selectedPartner = useSelector(state => state.partners.selectedPartner);
  const [customerType, setCustomerType] = useState('Truck');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const baseAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

  useEffect(() => {
    if (selectedPartner) {
      dispatch(fetchCustomersRequest(selectedPartner.id));
    }
  }, [dispatch, selectedPartner, customerType]);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleReview = (vendorID, id) => {
    navigate(`/customer/${vendorID}/${id}/view`);
  };

  const handleEdit = (vendorID, id) => {
    navigate(`/customer/${vendorID}/${id}/update`);
  };

  const handleDelete = async (vendorID, id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      const url = `${baseAPIURL}vendor_customer/${vendorID}/${id}`;
      console.log('url delete ', url);
      const response = await fetch(url, {
        method: "DELETE",
      });
      window.location.reload(true);
    }
  };  
  const getStatusBadge = (status) => {
    const statusMap = {
      Active: 'success',
      Inactive: 'danger',
    };
    return <span className={`badge bg-${statusMap[status] || 'secondary'}`}>{status}</span>;
  };

  const data = {
    columns: [
      {
        label: 'Photo',
        field: 'photo',
        sort: 'disabled',
        width: 100
      },
      {
        label: 'Customer Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 200
      }
    ],
    rows: Array.isArray(customers.customers) ? customers.customers.map(customer => ({
      ...customer,
      photo: customer.photo ? (
        <img src={customer.photo} alt="Customer" style={{ width: '100px', height: '50px' }} />
      ) : (
        <i className="mdi mdi-account-circle" style={{ fontSize: '30px' }}></i>
      ),
      status: getStatusBadge(customer.status),
      actions: (
        <div>
          <Button color="info" size="sm" className="me-2" onClick={() => handleReview(selectedPartner.id, customer.id)}>Review</Button>
          <Button color="warning" size="sm" className="me-2" onClick={() => handleEdit(selectedPartner.id, customer.id)}>Edit</Button>
          <Button color="danger" size="sm" onClick={() => handleDelete(selectedPartner.id,customer.id)}>Delete</Button>
        </div>
      )
    })) : []
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Home" breadcrumbItem="Admin Customers" addRoute="/customer/add" />
        <Row className="mb-4">
          <Col md={4} className="d-flex align-items-center">
            <Label for="partnerSelector" className="me-2">Partner:</Label>
            <PartnerSelector id="partnerSelector" />
          </Col>
          
          <Col md={4} className="text-end">
            <Button color="primary" onClick={() => navigate("/customer/add")}>
              <i className="mdi mdi-plus me-2"></i> Add New
            </Button>
          </Col>
        </Row>
        <div className="customer-list">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <MDBDataTable
              striped
              bordered
              small
              data={data}
              sortable
              searching={true}
              paging={true}
              responsive
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerList;