import {
  FETCH_VEHICLES_REQUEST,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLES_FAILURE,
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAILURE,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  FETCH_VEHICLE_REQUEST,
  FETCH_VEHICLE_SUCCESS,
  FETCH_VEHICLE_FAILURE,
} from "./actionTypes";

const initialState = {
  vehicles: [],
  selectedVehicle: null,
  loading: false,
  error: null,
};

const vehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
        loading: false,
      };
    case FETCH_VEHICLES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_VEHICLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_VEHICLE_SUCCESS:
      return {
        ...state,
        selectedVehicle: action.payload,
        loading: false,
      };
    case FETCH_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_VEHICLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_VEHICLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default vehicleReducer;