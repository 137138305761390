export const SET_SELECTED_PARTNER = "SET_SELECTED_PARTNER";
export const FETCH_PARTNERS_REQUEST = "FETCH_PARTNERS_REQUEST";
export const FETCH_PARTNERS_SUCCESS = "FETCH_PARTNERS_SUCCESS";
export const FETCH_PARTNERS_FAILURE = "FETCH_PARTNER_FAILURE";
export const FETCH_PARTNER_REQUEST = "FETCH_PARTNER_REQUEST";
export const FETCH_PARTNER_SUCCESS = "FETCH_PARTNER_SUCCESS";
export const FETCH_PARTNER_FAILURE = "FETCH_PARTNER_FAILURE";
export const ADD_PARTNER_REQUEST = "ADD_PARTNER_REQUEST";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAILURE = "ADD_PARTNER_FAILURE";
export const UPDATE_PARTNER_REQUEST = "UPDATE_PARTNER_REQUEST";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAILURE = "UPDATE_PARTNER_FAILURE";
