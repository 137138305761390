import React from 'react';
import { Row, Col, FormGroup, FormFeedback, FormText } from 'reactstrap';
import { useFormikContext } from 'formik';
import IMMasterDropdown from "../../components/ui/IMMasterDropdown";
import IMLocation from "../../components/ui/IMLocation";

const CustomerStatusLocationStep = () => {
  const formik = useFormikContext();

  return (
    <div>
      <h5>Customer Status & Location</h5>
      <Row>
        <Col md="6">
          <FormGroup>
            <IMMasterDropdown
              collectionName="status"
              selectedValue={formik.values.customerStatus}
              onChange={option => formik.setFieldValue("customerStatus", option.label)}
              label="Status"
            />
            <FormFeedback>{formik.errors.customerStatus}</FormFeedback>
            <FormText color="muted">Select the status of the customer.</FormText>
          </FormGroup>
        </Col>
        <Col md="6">
          <IMLocation
            label="Location"
            selectedLocation={formik.values.customerLocation}
            onLocationSelect={location => formik.setFieldValue("customerLocation", location)}
            formik={formik}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CustomerStatusLocationStep;