import React ,{useState} from "react";
import { useDispatch, useSelector} from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
  FormGroup,
  FormText
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addVehicleRequest } from "../../store/vehicles/actions";
import IMVendorUserComponent from "../../components/ui/IMVendorUserComponent"; // Asegúrate de ajustar la ruta según tu estructura de carpetas
import IMPartnerSelector from "../../components/ui/IMPartnerSelector"; // Ajusta la ruta según tu estructura de carpetas
import IMPhoto from "../../components/ui/IMPhoto"; // Asegúrate de ajustar la ruta según tu estructura de carpetas

import { useNavigate } from "react-router-dom";

const AddNewVehicle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector(state => state.vehicles);
  const selectedPartner = useSelector(state => state.partners.selectedPartner);
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});

  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Vehicle Name"),
    vin: Yup.string().required("Please Enter VIN/SN"),
    licensePlate: Yup.string().required("Please Enter License Plate"),
    fuelType: Yup.string().required("Please Select Fuel Type"),
    year: Yup.number().required("Please Enter Year").positive().integer(),
    make: Yup.string().required("Please Enter Manufacturer"),
    model: Yup.string().required("Please Enter Model"),
    registrationState: Yup.string().required("Please Enter Registration State/Province"),
    status: Yup.string().required("Please Select Status"),
    type: Yup.string().required("Please Select  Type"),

  });

  const formik = useFormik({
    initialValues: {
      name: "",
      vin: "",
      licensePlate: "",
      fuelType: "",
      year: "",
      make: "",
      model: "",
      registrationState: "",
      status: "",
      driver: "",
      dispatch: "",
      type:"",
      photo:""
    },
    validationSchema,
   onSubmit: (values) => {
      console.log('onSubmit')
      const vehicleData = {
        ...values,
        vendor: selectedPartner,
        vendorID: selectedPartner.id,
        driverID: values.driver ? values.driver.id : '',
        dispatchID: values.dispatch ? values.dispatch.id : '',
        vendorDispatch: values.dispatch || null,
        photo: modifiedNonFormData.photo || "",
       };
      console.log('onSubmit',vehicleData)
     
      dispatch(addVehicleRequest(vehicleData, navigate));
    }
      
  });
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Vehicles" breadcrumbItem="Add New Vehicle" />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                {error && <div className="alert alert-danger">{error}</div>}
                <Form onSubmit={formik.handleSubmit}>
                  <h2>Vehicle Information</h2>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="name">Vehicle Name</Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                          invalid={formik.touched.name && !!formik.errors.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <FormFeedback>{formik.errors.name}</FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Enter the name of the vehicle, e.g., "Partner Truck
                          1".
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="vin">VIN/SN</Label>
                        <Input
                          id="vin"
                          name="vin"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.vin}
                          invalid={formik.touched.vin && !!formik.errors.vin}
                        />
                        {formik.touched.vin && formik.errors.vin ? (
                          <FormFeedback>{formik.errors.vin}</FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Enter the Vehicle Identification Number or Serial
                          Number, e.g., "1HGCM82633A123456".
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="licensePlate">License Plate</Label>
                        <Input
                          id="licensePlate"
                          name="licensePlate"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.licensePlate}
                          invalid={
                            formik.touched.licensePlate &&
                            !!formik.errors.licensePlate
                          }
                        />
                        {formik.touched.licensePlate &&
                        formik.errors.licensePlate ? (
                          <FormFeedback>
                            {formik.errors.licensePlate}
                          </FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Enter the license plate number, e.g., "ABC-1234".
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="fuelType">Fuel Type</Label>
                        <Input
                          id="fuelType"
                          name="fuelType"
                          type="select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.fuelType}
                          invalid={
                            formik.touched.fuelType && !!formik.errors.fuelType
                          }
                        >
                          <option value="">Select Fuel Type</option>
                          <option value="Diesel">Diesel</option>
                          <option value="Gasoline">Gasoline</option>
                        </Input>
                        {formik.touched.fuelType && formik.errors.fuelType ? (
                          <FormFeedback>{formik.errors.fuelType}</FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Select the type of fuel the vehicle uses.
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="year">Year</Label>
                        <Input
                          id="year"
                          name="year"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.year}
                          invalid={formik.touched.year && !!formik.errors.year}
                        />
                        {formik.touched.year && formik.errors.year ? (
                          <FormFeedback>{formik.errors.year}</FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Enter the manufacturing year of the vehicle, e.g.,
                          "2020".
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="make">Make</Label>
                        <Input
                          id="make"
                          name="make"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.make}
                          invalid={formik.touched.make && !!formik.errors.make}
                        />
                        {formik.touched.make && formik.errors.make ? (
                          <FormFeedback>{formik.errors.make}</FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Enter the make of the vehicle, e.g., "Ford".
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="model">Model</Label>
                        <Input
                          id="model"
                          name="model"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.model}
                          invalid={
                            formik.touched.model && !!formik.errors.model
                          }
                        />
                        {formik.touched.model && formik.errors.model ? (
                          <FormFeedback>{formik.errors.model}</FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Enter the model of the vehicle, e.g., "F-150".
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="vehicleType">Vehicle Type</Label>
                        <Input
                          id="type"
                          name="type"
                          type="select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.type}
                          invalid={formik.touched.type && !!formik.errors.type}
                        >
                          <option value="">Select Vehicle Type</option>
                          <option value="Truck">Truck</option>
                          <option value="Trailer">Trailer</option>
                        </Input>
                        {formik.touched.type && formik.errors.type ? (
                          <FormFeedback>{formik.errors.type}</FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Select the type of vehicle truck, trailer.
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="status">Status</Label>
                        <Input
                          id="status"
                          name="status"
                          type="select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.status}
                          invalid={
                            formik.touched.status && !!formik.errors.status
                          }
                        >
                          <option value="">Select Status</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                          <option value="Out of Service">Out of Service</option>
                        </Input>
                        {formik.touched.status && formik.errors.status ? (
                          <FormFeedback>{formik.errors.status}</FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Select the status of the vehicle.
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      {selectedPartner ? (
                        <FormGroup>
                          <Label htmlFor="partner">Partner</Label>
                          <Input
                            id="partner"
                            name="partner"
                            type="text"
                            value={selectedPartner.title}
                            disabled
                          />
                        </FormGroup>
                      ) : (
                        <IMPartnerSelector />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="driver">Operator</Label>
                        <IMVendorUserComponent
                          vendorID={selectedPartner ? selectedPartner.id : ""}
                          role="driver"
                          onSelect={value =>
                            formik.setFieldValue("driver", value)
                          }
                        />
                        {formik.touched.driver && formik.errors.driver ? (
                          <FormFeedback>{formik.errors.driver}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="dispatch">Dispatch</Label>
                        <IMVendorUserComponent
                          vendorID={selectedPartner ? selectedPartner.id : ""}
                          role="dispatch"
                          onSelect={value =>
                            formik.setFieldValue("dispatch", value)
                          }
                        />
                        {formik.touched.dispatch && formik.errors.dispatch ? (
                          <FormFeedback>{formik.errors.dispatch}</FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="photo">Photo</Label>
                        <IMPhoto
                          formik={formik}
                          fieldName="photo"
                          currentPhoto={formik.values.photo}
                          onUpload={url => formik.setFieldValue("photo", url)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="registrationState">
                          Registration State/Province
                        </Label>
                        <Input
                          id="registrationState"
                          name="registrationState"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.registrationState}
                          invalid={
                            formik.touched.registrationState &&
                            !!formik.errors.registrationState
                          }
                        />
                        {formik.touched.registrationState &&
                        formik.errors.registrationState ? (
                          <FormFeedback>
                            {formik.errors.registrationState}
                          </FormFeedback>
                        ) : null}
                        <FormText color="muted">
                          Enter the state or province where the vehicle is
                          registered, e.g., "California".
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button type="submit" color="primary" disabled={loading}>
                    {loading ? "Loading..." : `Create `}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className="ml-2"
                    onClick={() => window.history.back()}
                  >
                    Back to Vehicle List
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
};

export default AddNewVehicle;