import {
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAILURE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  FETCH_MEMBER_REQUEST,
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBER_FAILURE,
} from "./actionTypes";

export const fetchMembersRequest = (companyId, memberType) => ({
  type: FETCH_MEMBERS_REQUEST,
  payload: { companyId, memberType },
});

export const fetchMembersSuccess = (members) => ({
  type: FETCH_MEMBERS_SUCCESS,
  payload: members,
});

export const fetchMembersFailure = (error) => ({
  type: FETCH_MEMBERS_FAILURE,
  payload: error,
});

export const fetchMemberRequest = (vendorID, memberId) => ({
  type: FETCH_MEMBER_REQUEST,
  payload: { vendorID, memberId },
});

export const fetchMemberSuccess = (member) => ({
  type: FETCH_MEMBER_SUCCESS,
  payload: member,
});

export const fetchMemberFailure = (error) => ({
  type: FETCH_MEMBER_FAILURE,
  payload: error,
});

export const addMemberRequest = (member, navigate) => ({
  type: ADD_MEMBER_REQUEST,
  payload: { member, navigate },
});

export const addMemberSuccess = (response) => ({
  type: ADD_MEMBER_SUCCESS,
  payload: response,
});

export const addMemberFailure = (error) => ({
  type: ADD_MEMBER_FAILURE,
  payload: error,
});

export const updateMemberRequest = (member, navigate) => ({
  type: UPDATE_MEMBER_REQUEST,
  payload: { member, navigate },
});

export const updateMemberSuccess = (member) => ({
  type: UPDATE_MEMBER_SUCCESS,
  payload: member,
});

export const updateMemberFailure = (error) => ({
  type: UPDATE_MEMBER_FAILURE,
  payload: error,
});