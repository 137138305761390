import {
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
} from "./actionTypes";

const initialState = {
  customers: [],
  selectedCustomer: null,
  loading: false,
  error: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        loading: false,
      };
    case FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        selectedCustomer: action.payload,
        loading: false,
      };
    case FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default customerReducer;