import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import axios from 'axios';

const baseAPIURL = process.env.REACT_APP_API_URL || 'https://us-central1-tobe-services.cloudfunctions.net/app/api/';


class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          user.getIdToken().then(token => {
            localStorage.setItem("authUser", JSON.stringify(user));
            const bearerToken = `Bearer ${token}`;
            // console.log('bearerToken on authchange', bearerToken)
            // localStorage.setItem("jwtToken", bearerToken);
            // this.setAuthToken(bearerToken);
          });
        } else {
          localStorage.removeItem("authUser");
          localStorage.removeItem("jwtToken");
          this.setAuthToken(null);
        }
      });
    }
    this.functions = firebase.functions();
  }

  setAuthToken = token => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token; // El token ya incluye "Bearer "
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          const uid = response.user.uid;
          const data = {
            id: uid,
            userId: uid,
            email,
          };

          delete axios.defaults.headers.common["Authorization"];
          axios
            .post(`${baseAPIURL}login`, data)
            .then((response) => {
              const bearerToken = "Bearer " + response.data.token;
              localStorage.setItem("jwtToken", bearerToken);
              this.setAuthToken(bearerToken);
              resolve(bearerToken); // Resolver con el token
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem("authUser");
          localStorage.removeItem("jwtToken");
          this.setAuthToken(null); // Eliminar el token de las cabeceras
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

let _fireBaseBackend = null;

const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };