import React, { useState, useEffect } from 'react';
import { Input, FormText, FormGroup, Label } from 'reactstrap';

const IMPhoto = ({ currentPhoto, onUpload, fieldName, formik }) => {
  const [imageURL, setImageURL] = useState(currentPhoto);

  useEffect(() => {
    setImageURL(currentPhoto);
  }, [currentPhoto]);

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("photos", files[i]);
    }

    const baseAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

    fetch(`${baseAPIURL}upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        const url = response.data && response.data[0] && response.data[0].url;
        setImageURL(url); // Actualizar la URL de la imagen
        formik.setFieldValue(fieldName, url);
        onUpload(url);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <FormGroup>
      <Label htmlFor={fieldName}>Photo</Label>
      {imageURL && (
        <div className="mb-3">
          <img src={imageURL} alt="Member" style={{ width: '150px', height: '150px' }} />
        </div>
      )}
      <Input
        className="FormFileField"
        id={fieldName}
        name={fieldName}
        type="file"
        onChange={handleImageUpload}
      />
      <FormText color="muted">Upload a photo of the member.</FormText>
    </FormGroup>
  );
};

export default IMPhoto;