import axios from "axios";
import setAuthToken from "./setAuthToken";

const API_URL = process.env.REACT_APP_API_URL || 'https://us-central1-tobe-services.cloudfunctions.net/app/api';

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem("jwtToken");
    // console.log('token interceptado',token)
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then(response => response.data);
}