import {
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAILURE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  FETCH_MEMBER_REQUEST,
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBER_FAILURE,
} from "./actionTypes";

const initialState = {
  members: [],
  selectedMember: null,
  loading: false,
  error: null,
};

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload,
        loading: false,
      };
    case FETCH_MEMBERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        selectedMember: action.payload || null,
        loading: false,
      };
    case FETCH_MEMBER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_MEMBER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_MEMBER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default membersReducer;