import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import vehicleReducer from "./vehicles/reducer"
import {partnersReducer} from "./partners"; // Importa el nuevo reducer
import membersReducer from "./members/reducer"; 
import rolesReducer from "./roles/reducer"; 
import masterReducer from "./masters/reducer";
import servicesReducer from "./masters/services/reducer";
import statusReducer from './masters/status/reducer'
import customerReducer from './customers/reducer'
//Calendar
import calendar from "./calendar/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  partners: partnersReducer,
  vehicles: vehicleReducer,
  members: membersReducer,
  roles: rolesReducer,
  masters: masterReducer,
  services: servicesReducer,
  status: statusReducer,
  customers: customerReducer
})

export default rootReducer
