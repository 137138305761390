import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firestore = firebase.firestore();

export const addMasterItem = async (collectionName, item) => {
  try {
    const collectionRef = firestore.collection('MasterTables').doc(collectionName).collection(collectionName);
    const docRef = await collectionRef.add(item);
    return { id: docRef.id, ...item };
  } catch (error) {
    throw new Error("Error adding item to Firestore: " + error.message);
  }
};

export const getMasterItems = async (collectionName) => {
  try {
    const collectionRef = firestore.collection('MasterTables').doc(collectionName).collection(collectionName);
    const snapshot = await collectionRef.get();
    const items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log(' items fetch mastertables',items)
    return items;
  } catch (error) {
    throw new Error("Error fetching items from Firestore: " + error.message);
  }
};

export const updateMasterItem = async (collectionName, itemId, updatedItem) => {
  try {
    const itemRef = firestore.collection('MasterTables').doc(collectionName).collection(collectionName).doc(itemId);
    await itemRef.update(updatedItem);
    return { id: itemId, ...updatedItem };
  } catch (error) {
    throw new Error("Error updating item in Firestore: " + error.message);
  }
};

export const deleteMasterItem = async (collectionName, itemId) => {
  try {
    const itemRef = firestore.collection('MasterTables').doc(collectionName).collection(collectionName).doc(itemId);
    await itemRef.delete();
    return itemId;
  } catch (error) {
    throw new Error("Error deleting item from Firestore: " + error.message);
  }
};
export const uploadPhoto = async (file) => {
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`photos/${file.name}`);
    await fileRef.put(file);
    return await fileRef.getDownloadURL();
  };

  export const addServiceToCategory = async (categoryName, service) => {
    try {
      const categoryRef = firestore.collection('MasterTables').doc('services').collection(categoryName);
      const docRef = await categoryRef.add(service);
      return { id: docRef.id, ...service };
    } catch (error) {
      throw new Error("Error adding service to Firestore: " + error.message);
    }
  };
  export const getServicesByCategory = async (categoryName) => {
    try {
      const categoryRef = firestore.collection('MasterTables').doc('services').collection(categoryName);
      const snapshot = await categoryRef.get();
      const services = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return services;
    } catch (error) {
      throw new Error("Error fetching services from Firestore: " + error.message);
    }
  };

  export const updateServiceInCategory = async (categoryName, serviceId, updatedService) => {
    try {
      const serviceRef = firestore.collection('MasterTables').doc('services').collection(categoryName).doc(serviceId);
      await serviceRef.update(updatedService);
      return { id: serviceId, ...updatedService };
    } catch (error) {
      throw new Error("Error updating service in Firestore: " + error.message);
    }
  };
  export const deleteServiceFromCategory = async (categoryName, serviceId) => {
    try {
      const serviceRef = firestore.collection('MasterTables').doc('services').collection(categoryName).doc(serviceId);
      await serviceRef.delete();
      return serviceId;
    } catch (error) {
      throw new Error("Error deleting service from Firestore: " + error.message);
    }
  };


  export const addSubcategoryToService = async (serviceId, subcategory) => {
    try {
      console.log ('subcategory helper',serviceId, subcategory )
      const subcategoryRef = firestore.collection('MasterTables').doc('services').collection('services').doc(serviceId).collection('subcategories');
      const docRef = await subcategoryRef.add(subcategory);
      return { id: docRef.id, ...subcategory };
    } catch (error) {
      throw new Error("Error adding subcategory to Firestore: " + error.message);
    }
  };

  export const getSubcategoriesByService = async (serviceId) => {
    try {
      const subcategoryRef = firestore.collection('MasterTables').doc('services').collection('services').doc(serviceId).collection('subcategories');
      const snapshot = await subcategoryRef.get();
      const subcategories = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return subcategories;
    } catch (error) {
      throw new Error("Error fetching subcategories from Firestore: " + error.message);
    }
  };

  export const updateSubcategoryInService = async (serviceId, subcategoryId, updatedSubcategory) => {
    try {
      const subcategoryRef = firestore.collection('MasterTables').doc('services').collection('services').doc(serviceId).collection('subcategories').doc(subcategoryId);
      await subcategoryRef.update(updatedSubcategory);
      return { id: subcategoryId, ...updatedSubcategory };
    } catch (error) {
      throw new Error("Error updating subcategory in Firestore: " + error.message);
    }
  };
  export const deleteSubcategoryFromService = async (serviceId, subcategoryId) => {
    try {
      const subcategoryRef = firestore.collection('MasterTables').doc('services').collection('services').doc(serviceId).collection('subcategories').doc(subcategoryId);
      await subcategoryRef.delete();
      return subcategoryId;
    } catch (error) {
      throw new Error("Error deleting subcategory from Firestore: " + error.message);
    }
  };