import {
  FETCH_MASTER_ITEMS_REQUEST,
  FETCH_MASTER_ITEMS_SUCCESS,
  FETCH_MASTER_ITEMS_FAILURE,
  ADD_MASTER_ITEM_REQUEST,
  ADD_MASTER_ITEM_SUCCESS,
  ADD_MASTER_ITEM_FAILURE,
  UPDATE_MASTER_ITEM_REQUEST,
  UPDATE_MASTER_ITEM_SUCCESS,
  UPDATE_MASTER_ITEM_FAILURE,
  DELETE_MASTER_ITEM_REQUEST,
  DELETE_MASTER_ITEM_SUCCESS,
  DELETE_MASTER_ITEM_FAILURE,
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILURE,
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_SUBCATEGORIES_REQUEST,
  FETCH_SUBCATEGORIES_SUCCESS,
  FETCH_SUBCATEGORIES_FAILURE,
  ADD_SUBCATEGORY_REQUEST,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_FAILURE,
  UPDATE_SUBCATEGORY_REQUEST,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_FAILURE,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAILURE,
} from "./types"
  
  // Fetch master items
  export const fetchMasterItemsRequest = (collectionName) => {
    console.log("Disparando fetchMasterItemsRequest con", collectionName); // Añade este log
    return {
      type: FETCH_MASTER_ITEMS_REQUEST,
      payload: collectionName,
    };
  };
 
  export const fetchMasterItemsSuccess = (collectionName, items) => ({
    type: FETCH_MASTER_ITEMS_SUCCESS,
    payload: { collectionName, items },
  });
  
  export const fetchMasterItemsFailure = (collectionName, error) => ({
    type: FETCH_MASTER_ITEMS_FAILURE,
    payload: { collectionName, error },
  });
  
  // Add master item
  export const addMasterItemRequest = (collectionName, item) => ({
    type: ADD_MASTER_ITEM_REQUEST,
    payload: { collectionName, item },
  });
  
  export const addMasterItemSuccess = (collectionName, item) => ({
    type: ADD_MASTER_ITEM_SUCCESS,
    payload: { collectionName, item },
  });
  
  export const addMasterItemFailure = (collectionName, error) => ({
    type: ADD_MASTER_ITEM_FAILURE,
    payload: { collectionName, error },
  });
  
  // Update master item
  export const updateMasterItemRequest = (collectionName, itemId, updatedItem) => ({
    type: UPDATE_MASTER_ITEM_REQUEST,
    payload: { collectionName, itemId, updatedItem },
  });
  
  export const updateMasterItemSuccess = (collectionName, item) => ({
    type: UPDATE_MASTER_ITEM_SUCCESS,
    payload: { collectionName, item },
  });
  
  export const updateMasterItemFailure = (collectionName, error) => ({
    type: UPDATE_MASTER_ITEM_FAILURE,
    payload: { collectionName, error },
  });
  
  // Delete master item
  export const deleteMasterItemRequest = (collectionName, itemId) => ({
    type: DELETE_MASTER_ITEM_REQUEST,
    payload: { collectionName, itemId },
  });
  
  export const deleteMasterItemSuccess = (collectionName, itemId) => ({
    type: DELETE_MASTER_ITEM_SUCCESS,
    payload: { collectionName, itemId },
  });
  
  export const deleteMasterItemFailure = (collectionName, error) => ({
    type: DELETE_MASTER_ITEM_FAILURE,
    payload: { collectionName, error },
  });
  
  // Upload photo
  export const uploadPhotoRequest = (file) => ({
    type: UPLOAD_PHOTO_REQUEST,
    payload: file,
  });
  
  export const uploadPhotoSuccess = (url) => ({
    type: UPLOAD_PHOTO_SUCCESS,
    payload: url,
  });
  
  export const uploadPhotoFailure = (error) => ({
    type: UPLOAD_PHOTO_FAILURE,
    payload: error,
  });
  // Services actions
export const fetchServicesRequest = () => ({
  type: FETCH_SERVICES_REQUEST,
});

export const fetchServicesSuccess = (services) => ({
  type: FETCH_SERVICES_SUCCESS,
  payload: services,
});

export const fetchServicesFailure = (error) => ({
  type: FETCH_SERVICES_FAILURE,
  payload: error,
});

// Status actions
export const fetchStatusRequest = () => ({
  type: FETCH_STATUS_REQUEST,
});

export const fetchStatusSuccess = (status) => ({
  type: FETCH_STATUS_SUCCESS,
  payload: status,
});

export const fetchStatusFailure = (error) => ({
  type: FETCH_STATUS_FAILURE,
  payload: error,
});

// Action Creators
export const fetchSubcategoriesRequest = (serviceId) => ({
  type: FETCH_SUBCATEGORIES_REQUEST,
  payload: { serviceId },
});

export const fetchSubcategoriesSuccess = (subcategories) => ({
  type: FETCH_SUBCATEGORIES_SUCCESS,
  payload: subcategories,
});

export const fetchSubcategoriesFailure = (error) => ({
  type: FETCH_SUBCATEGORIES_FAILURE,
  payload: error,
});

export const addSubcategoryRequest = (serviceId, subcategory) => ({
  type: ADD_SUBCATEGORY_REQUEST,
  payload: { serviceId, subcategory },
});

export const addSubcategorySuccess = (subcategory) => ({
  type: ADD_SUBCATEGORY_SUCCESS,
  payload: subcategory,
});

export const addSubcategoryFailure = (error) => ({
  type: ADD_SUBCATEGORY_FAILURE,
  payload: error,
});

export const updateSubcategoryRequest = (serviceId, subcategoryId, updatedSubcategory) => ({
  type: UPDATE_SUBCATEGORY_REQUEST,
  payload: { serviceId, subcategoryId, updatedSubcategory },
});

export const updateSubcategorySuccess = (subcategory) => ({
  type: UPDATE_SUBCATEGORY_SUCCESS,
  payload: subcategory,
});

export const updateSubcategoryFailure = (error) => ({
  type: UPDATE_SUBCATEGORY_FAILURE,
  payload: error,
});

export const deleteSubcategoryRequest = (serviceId, subcategoryId) => ({
  type: DELETE_SUBCATEGORY_REQUEST,
  payload: { serviceId, subcategoryId },
});

export const deleteSubcategorySuccess = (subcategoryId) => ({
  type: DELETE_SUBCATEGORY_SUCCESS,
  payload: subcategoryId,
});

export const deleteSubcategoryFailure = (error) => ({
  type: DELETE_SUBCATEGORY_FAILURE,
  payload: error,
});