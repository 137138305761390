import { call, put, takeLatest,all } from 'redux-saga/effects';
import { FETCH_PARTNERS_REQUEST, ADD_PARTNER_REQUEST ,UPDATE_PARTNER_REQUEST,FETCH_PARTNER_REQUEST} from './types';
import { fetchPartnersSuccess, fetchPartnersFailure, addPartnerSuccess, addPartnerFailure ,updatePartnerSuccess,
  updatePartnerFailure, fetchPartnerRequest,fetchPartnerSuccess,fetchPartnerFailure} from './actions';
import { get, post ,put as apiPut } from '../../helpers/api_helper';

const baseAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

// Fetch partners
function* fetchPartners() {
  try {
    const response = yield call(get, `${baseAPIURL}companies`);
    yield put(fetchPartnersSuccess(response.companies));
  } catch (error) {
    yield put(fetchPartnersFailure(error.message));
  }
}
function* fetchPartnerSaga(action) {
  try {
    console.log( 'action.payload', action.payload)
    const { partnerId } = action.payload;
    const url = `/company/${partnerId}`;
    console.log('urlfetch partner', url);
    const response = yield call(get, url);
    yield put(fetchPartnerSuccess(response));
  } catch (error) {
    yield put(fetchPartnerFailure(error.message));
  }
}

// Add partner
function* addPartner(action) {
  try {
    const response = yield call(post, `${baseAPIURL}company/add`, action.payload.partnerData);
    yield put(addPartnerSuccess(response));
    action.payload.navigate('/partners'); // Redirect to partners list
  } catch (error) {
    yield put(addPartnerFailure(error.message));
  }
}
function* updatePartnerSaga(action) {
  try {
    const { partnerData, navigate } = action.payload;
    const url = `company/${partnerData.id}`;
    console.log("updatePartner url", url);
    const response = yield call(apiPut, url, partnerData);
    yield put(updatePartnerSuccess(response));
    navigate("/partners");
  } catch (error) {
    console.log('error',error)
    yield put(updatePartnerFailure(error.message));
    navigate("/partners");
  }
}

export function* watchFetchPartners() {
  yield takeLatest(FETCH_PARTNERS_REQUEST, fetchPartners);
}
export function* watchFetchPartner() {
  yield takeLatest(FETCH_PARTNER_REQUEST, fetchPartnerSaga);
}


export function* watchAddPartner() {
  yield takeLatest(ADD_PARTNER_REQUEST, addPartner);
}
export function* watchUpdatePartners() {
  yield takeLatest(UPDATE_PARTNER_REQUEST, updatePartnerSaga);
}

export default function* partnerSaga() {
  yield all([
    watchFetchPartners(),
    watchAddPartner(),
    watchUpdatePartners() ,
    watchFetchPartner(),


  ]);
}