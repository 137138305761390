import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  FormGroup,
  FormText
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { fetchPartnerRequest, updatePartnerRequest } from "../../store/partners/actions";
import IMPartnerSelector from "../../components/ui/IMPartnerSelector";
import IMMasterDropdown from "../../components/ui/IMMasterDropdown";
import IMMasterMultiSelect from "../../components/ui/IMMasterMultiSelect";
import IMLocation from "../../components/ui/IMLocation";
import { useNavigate, useParams } from "react-router-dom";
import IMPhoto from "../../components/ui/IMPhoto";

const UpdatePartner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { partnerId } = useParams();
  console.log(' parnter update',partnerId)
  const { selectedPartner, loading, error } = useSelector((state) => state.partners);

  useEffect(() => {
    if (partnerId) {
      dispatch(fetchPartnerRequest(partnerId));
      console.log ('partnerId',partnerId,selectedPartner)
    }
  }, [dispatch, partnerId]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Please Enter Partner Name"),
    email: Yup.string().email("Invalid email format").required("Please Enter Email"),
    // status: Yup.object().required("Please Select Status"),
    services: Yup.array().min(1, "Select at least one service").required("Please Select Services"),
    location: Yup.object().shape({
      label: Yup.string().required("Please select a location")
    }).nullable().required("Please select a location"),
  });

  const handleSubmit = (values) => {
    const partnerData = {
      ...values,
      id: partnerId,
    };
    console.log('partnerId',partnerId)
    console.log('partnerData',partnerData)
    dispatch(updatePartnerRequest(partnerData, navigate));
  };

  const initialValues = {
    title: selectedPartner ? selectedPartner.title : "",
    email: selectedPartner ? selectedPartner.email : "",
    // status: selectedPartner ? { value: selectedPartner.status, label: selectedPartner.label } : "",
    status: selectedPartner ? selectedPartner.status  : "",
    services: selectedPartner ? selectedPartner.services : [],
    location: selectedPartner ? selectedPartner.location : null,
    photo: selectedPartner ? selectedPartner.photo : "",
   
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Partners" breadcrumbItem="Update Partner" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {error && <div className="alert alert-danger">{error


}</div>}
<Formik
  enableReinitialize
  initialValues={initialValues}
  validationSchema={validationSchema}
  onSubmit={handleSubmit}
>
  {formik => (
    <Form>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="title">Partner Name</Label>
            <Input
              id="title"
              name="title"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              invalid={formik.touched.title && !!formik.errors.title}
            />
            <FormFeedback>{formik.errors.title}</FormFeedback>
            <FormText color="muted">Enter the name of the partner.</FormText>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              invalid={formik.touched.email && !!formik.errors.email}
            />
            <FormFeedback>{formik.errors.email}</FormFeedback>
            <FormText color="muted">Enter the email address of the partner.</FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row>
      <Col md="6">
          <FormGroup>
            <Label htmlFor="services">Services</Label>
            <IMMasterMultiSelect
              collectionName="services"
              selectedValues={formik.values.services}
              onChange={(value) => formik.setFieldValue("services", value)}
              label="Services"
            />
            <FormFeedback>{formik.errors.services}</FormFeedback>
            <FormText color="muted">Select one or more services for the partner.</FormText>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="status">Status</Label>
            <IMMasterDropdown
              collectionName="status"
              selectedValue={formik.values.status}
              onChange={(option) => formik.setFieldValue("status", option.label)}
              label="Status"
            />
            <FormFeedback>{formik.errors.status}</FormFeedback>
            <FormText color="muted">Select the status of the partner.</FormText>
          </FormGroup>
        </Col>
      
      </Row>
      <Row>
        <Col md="12">
          <Label htmlFor="location">Location</Label>
          <IMLocation
            label="Location"
            selectedLocation={formik.values.location}
            onLocationSelect={(location) => formik.setFieldValue("location", location)}
            formik={formik}
          />
          {formik.touched.location && formik.errors.location && (
            <FormFeedback>{formik.errors.location.label}</FormFeedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <IMPhoto
            currentPhoto={formik.values.photo}
            onUpload={(url) => formik.setFieldValue("photo", url)}
            fieldName="photo"
            formik={formik}
          />
        </Col>
      </Row>
      <Button type="submit" color="primary" disabled={loading}>
        {loading ? "Loading..." : "Update"}
      </Button>
      <Button
        type="button"
        color="secondary"
        className="ml-2"
        onClick={() => window.history.back()}
      >
        Back to Partner List
      </Button>
    </Form>
  )}
</Formik>
</CardBody>
</Card>
</Col>
</Row>
</div>
);
};

export default UpdatePartner;