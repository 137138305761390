import { call, all, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_CUSTOMERS_REQUEST,
  ADD_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_REQUEST,
} from "./actionTypes"
import {
  fetchCustomersSuccess,
  fetchCustomersFailure,
  addCustomerFailure,
  addCustomerSuccess,
  updateCustomerSuccess,
  updateCustomerFailure,
  fetchCustomerSuccess,
  fetchCustomerFailure,
} from "./actions"
import { get, post, put as apiPut } from '../../helpers/api_helper'; // Renombrar 'put' a 'apiPut'

function* fetchCustomers(action) {
  try {
    const { companyId} = action.payload;
    const response = yield call(get, `/vendor_customers?vendorID=${companyId}`);
    yield put(fetchCustomersSuccess(response));
  } catch (error) {
    yield put(fetchCustomersFailure(error.message));
  }
}

function* fetchCustomer(action) {
  try {
    const { vendorID, customerId } = action.payload;
    const url = `/vendor_customer/${vendorID}/${customerId}`;
    const response = yield call(get, url);
    yield put(fetchCustomerSuccess(response));
  } catch (error) {
    yield put(fetchCustomerFailure(error.message));
  }
}

function* addCustomer(action) {
  try {
    const response = yield call(post, '/vendor_customer/add', action.payload.customer);
    yield put(addCustomerSuccess(response));
    action.payload.navigate("/customers");
  } catch (error) {
    yield put(addCustomerFailure(error.message));
    action.payload.navigate("/customers"); // O manejar el error de otra forma
  }
}

function* updateCustomer(action) {
  try {
    // const response = await fetch(`${baseAPIURL}customers/${vendorID}/${customerId}`, {
      const { customer, navigate } = action.payload;
    const url = `vendor_customer/${customer.vendorID}/${customer.id}` 
    console.log("updateCustomer url" , url, customer) ;
    const response = yield call(apiPut, url, action.payload.customer);
    yield put(updateCustomerSuccess(response));
    action.payload.navigate("/customers")
  } catch (error) {
    console.log('error.message',error.message)
    yield put(updateCustomerFailure(error.message));
    action.payload.navigate("/customers");
  }
}

export function* watchFetchCustomers() {
  yield takeLatest(FETCH_CUSTOMERS_REQUEST, fetchCustomers);
}

export function* watchFetchCustomer() {
  yield takeLatest(FETCH_CUSTOMER_REQUEST, fetchCustomer);
}

export function* watchAddCustomer() {
  yield takeLatest(ADD_CUSTOMER_REQUEST, addCustomer);
}

export function* watchUpdateCustomer() {
  yield takeLatest(UPDATE_CUSTOMER_REQUEST, updateCustomer);
}

export default function* customerSaga() {
  yield all([
    watchFetchCustomers(),
    watchFetchCustomer(),
    watchAddCustomer(),
    watchUpdateCustomer(),
  ]);
}