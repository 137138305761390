import {
  FETCH_VEHICLES_REQUEST,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLES_FAILURE,
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAILURE,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  FETCH_VEHICLE_REQUEST,
  FETCH_VEHICLE_SUCCESS,
  FETCH_VEHICLE_FAILURE,
} from "./actionTypes";

export const fetchVehiclesRequest = (companyId, vehicleType) => ({
  type: FETCH_VEHICLES_REQUEST,
  payload: { companyId, vehicleType },
});

export const fetchVehiclesSuccess = (vehicles) => ({
  type: FETCH_VEHICLES_SUCCESS,
  payload: vehicles,
});

export const fetchVehiclesFailure = (error) => ({
  type: FETCH_VEHICLES_FAILURE,
  payload: error,
});

export const fetchVehicleRequest = (vendorID, vehicleId) => ({
  type: FETCH_VEHICLE_REQUEST,
  payload: { vendorID, vehicleId },
});

export const fetchVehicleSuccess = (vehicle) => ({
  type: FETCH_VEHICLE_SUCCESS,
  payload: vehicle,
});

export const fetchVehicleFailure = (error) => ({
  type: FETCH_VEHICLE_FAILURE,
  payload: error,
});

export const addVehicleRequest = (vehicle, navigate) => ({
  type: ADD_VEHICLE_REQUEST,
  payload: { vehicle, navigate },
});

export const addVehicleSuccess = (vehicle) => ({
  type: ADD_VEHICLE_SUCCESS,
  payload: vehicle,
});

export const addVehicleFailure = (error) => ({
  type: ADD_VEHICLE_FAILURE,
  payload: error,
});

export const updateVehicleRequest = (vehicle, navigate) => ({
  type: UPDATE_VEHICLE_REQUEST,
  payload: { vehicle, navigate },
});

export const updateVehicleSuccess = (vehicle) => ({
  type: UPDATE_VEHICLE_SUCCESS,
  payload: vehicle,
});

export const updateVehicleFailure = (error) => ({
  type: UPDATE_VEHICLE_FAILURE,
  payload: error,
});