import {
  SET_SELECTED_PARTNER,
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  FETCH_PARTNER_REQUEST,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_FAILURE,
  ADD_PARTNER_REQUEST,
  ADD_PARTNER_SUCCESS,
  ADD_PARTNER_FAILURE,
  UPDATE_PARTNER_REQUEST,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAILURE,

} from "./types";

export const setSelectedPartner = (partner) => ({
  type: SET_SELECTED_PARTNER,
  payload: partner,
});

export const fetchPartnersRequest = () => ({
  type: FETCH_PARTNERS_REQUEST,
});

export const fetchPartnersSuccess = (partners) => ({
  type: FETCH_PARTNERS_SUCCESS,
  payload: partners,
});

export const fetchPartnersFailure = (error) => ({
  type: FETCH_PARTNERS_FAILURE,
  payload: error,
});
export const fetchPartnerRequest = (partnerId) => ({
  type: FETCH_PARTNER_REQUEST,
  payload: { partnerId },
});

export const fetchPartnerSuccess = (partners) => ({
  type: FETCH_PARTNER_SUCCESS,
  payload: partners,
});

export const fetchPartnerFailure = (error) => ({
  type: FETCH_PARTNER_FAILURE,
  payload: error,
});
// Add partner
export const addPartnerRequest = (partnerData, navigate) => ({
  type: ADD_PARTNER_REQUEST,
  payload: { partnerData, navigate },
});

export const addPartnerSuccess = (partner) => ({
  type: ADD_PARTNER_SUCCESS,
  payload: partner,
});

export const addPartnerFailure = (error) => ({
  type: ADD_PARTNER_FAILURE,
  payload: error,
});


export const updatePartnerRequest = (partnerData, navigate) => ({
  type: UPDATE_PARTNER_REQUEST,
  payload: { partnerData, navigate },
});

export const updatePartnerSuccess = (partner) => ({
  type: UPDATE_PARTNER_SUCCESS,
  payload: partner,
});

export const updatePartnerFailure = (error) => ({
  type: UPDATE_PARTNER_FAILURE,
  payload: error,
});