import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addCustomerRequest } from "../../store/customers/actions";
import { useNavigate } from "react-router-dom";
import CustomerMemberDetailsStep from "./CustomerMemberDetailsStep";
import CustomerStatusLocationStep from "./CustomerStatusLocationStep";
import MemberRolesPhotoStep from "./MemberRolesPhotoStep";
import SearchComponent from "./searchCustomer";
const AddBusiness = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerLoading, customerError } = useSelector((state) => state.customers);
  const selectedPartner = useSelector(state => state.partners.selectedPartner);
  const [activeStep, setActiveStep] = useState(0);

  const validationSchema = Yup.object({
    customerName: Yup.string().required("Please Enter Customer Name"),
    customerEmail: Yup.string().email("Invalid email format").required("Please Enter Customer Email"),
    customerStatus: Yup.string().required("Please Select Customer Status"),
    customerLocation: Yup.object().shape({
      label: Yup.string().required("Please select a location")
    }).nullable().required("Please select a location"),
    memberFirstName: Yup.string().required("Please Enter First Name"),
    memberLastName: Yup.string().required("Please Enter Last Name"),
    memberEmail: Yup.string().email("Invalid email format").required("Please Enter Member Email"),
    memberRoles: Yup.array().min(1, "Select at least one role").required("Please Select Roles"),
    // memberPhoto: Yup.string().required("Please upload a photo")
  });

  const steps = [
    { component: SearchComponent, label: "Search Customers" },
    { component: CustomerMemberDetailsStep, label: "Customer & Member Details" },
    { component: CustomerStatusLocationStep, label: "Customer Status & Location" },
    { component: MemberRolesPhotoStep, label: "Member Roles & Photo" }
  ];

  const handleSubmit = (values) => {
    const memberData = {
      firstName: values.memberFirstName,
      lastName: values.memberLastName,
      email: values.memberEmail,
      roles: values.memberRoles,
      photo: values.memberPhoto,
      vendorID: selectedPartner.id,
      vendor: selectedPartner,
      status: values.customerStatus
    };

    const customerData = {
      name: values.customerName,
      email: values.customerEmail,
      status: values.customerStatus,
      location: values.customerLocation,
      vendor: selectedPartner,
      vendorID: selectedPartner.id,
      contact: memberData
    };

    dispatch(addCustomerRequest(customerData, navigate));
  };

  const initialValues = {
    customerName: "",
    customerEmail: "",
    customerStatus: "",
    customerLocation: null,
    memberFirstName: "",
    memberLastName: "",
    memberEmail: "",
    memberRoles: [],
    memberPhoto: "",
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Customers & Members" breadcrumbItem="Add New Customer & Member" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {(customerError) && <div className="alert alert-danger">{customerError}</div>}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <Form>
                    {React.createElement(steps[activeStep].component)}

                    <div className="actions clearfix">
                      <ul>
                        <li className={activeStep === 0 ? "previous disabled" : "previous"}>
                          <Button
                            type="button"
                            color="secondary"
                            onClick={() => setActiveStep(activeStep - 1)}
                            disabled={activeStep === 0}
                          >
                            Previous
                          </Button>
                        </li>
                        <li className={activeStep === steps.length - 1 ? "next disabled" : "next"}>
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => setActiveStep(activeStep + 1)}
                            disabled={activeStep === steps.length - 1}
                          >
                            Next
                          </Button>
                        </li>
                      </ul>
                    </div>

                    {activeStep === steps.length - 1 && (
                      <Button type="submit" color="primary" disabled={customerLoading}>
                        {customerLoading ? "Loading..." : "Create"}
                      </Button>
                    )}
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddBusiness;