import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Card, CardBody, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { fetchVehicleRequest, updateVehicleRequest } from '../../store/vehicles/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import IMPartnerSelector from '../../components/ui/IMPartnerSelector';
import IMVendorUserComponent from "../../components/ui/IMVendorUserComponent";
import IMPhoto from "../../components/ui/IMPhoto";

const UpdateVehicle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorID, vehicleId } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: '',
    vin: '',
    licensePlate: '',
    fuelType: '',
    year: '',
    make: '',
    model: '',
    registrationState: '',
    status: '',
    driver: '',
    dispatch: '',
    photo: '',
  });

  const selectedVehicle = useSelector(state => state.vehicles.selectedVehicle);
  const selectedPartner = useSelector(state => state.partners.selectedPartner);

  useEffect(() => {
    if (vendorID && vehicleId) {
      dispatch(fetchVehicleRequest(vendorID, vehicleId));
    }
  }, [dispatch, vendorID, vehicleId]);

  useEffect(() => {
    if (selectedVehicle) {
      setInitialValues({
        ...selectedVehicle,
        driver: selectedVehicle.driverID || '',
        dispatch: selectedVehicle.dispatchID || '',
      });
    }
  }, [selectedVehicle]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Field Required!"),
    vin: Yup.string().required("Field Required!"),
    licensePlate: Yup.string().required("Field Required!"),
    fuelType: Yup.string().required("Field Required!"),
    year: Yup.number().required("Field Required!"),
    make: Yup.string().required("Field Required!"),
    model: Yup.string().required("Field Required!"),
    status: Yup.string().required("Field Required!"),
  });

  const handleSubmit = (values) => {
    const vehicleData = {
      ...values,
      vendorID: selectedPartner.id,
      driverID: values.driver ? values.driver.id : '',
      dispatchID: values.dispatch ? values.dispatch.id : '',
      vendorDispatch: values.dispatch,
      driver: values.driver,
      photo: values.photo || selectedVehicle.photo || "",
    };
    dispatch(updateVehicleRequest(vehicleData, navigate));
  };

  return (
    <div className="page-content">

        <Breadcrumbs title="Home" breadcrumbItem="Update  Vehicle" />
      
  <Row>
    <Col lg={12}>
      <Card>
        <CardBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {formik => (
              <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="name">Vehicle Name</Label>
                      <Field
                        name="name"
                        type="text"
                        className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage name="name" component="div" className="invalid-feedback" />
                      <small className="form-text text-muted">
                        Enter the name of the vehicle, e.g., "Partner Truck 1".
                      </small>                   
                       </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="vin">VIN/SN</Label>
                      <Field
                        name="vin"
                        type="text"
                        className={`form-control ${formik.touched.vin && formik.errors.vin ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage name="vin" component="div" className="invalid-feedback" />
                      <small className="form-text text-muted">
                        Enter the Vehicle Identification Number or Serial
                        Number, e.g., "1HGCM82633A123456".
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="licensePlate">License Plate</Label>
                      <Field
                        name="licensePlate"
                        type="text"
                        className={`form-control ${formik.touched.licensePlate && formik.errors.licensePlate ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage name="licensePlate" component="div" className="invalid-feedback" />
                      <small className="form-text text-muted">
                        Enter the license plate number, e.g., "ABC-1234".
                      </small>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="fuelType">Fuel Type</Label>
                      <Field
                        name="fuelType"
                        as="select"
                        className={`form-control ${formik.touched.fuelType && formik.errors.fuelType ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select Fuel Type</option>
                        <option value="Diesel">Diesel</option>
                        <option value="Gasoline">Gasoline</option>
                      </Field>
                      <ErrorMessage name="fuelType" component="div" className="invalid-feedback" />
                      <small className="form-text text-muted">
                        Select the type of fuel the vehicle uses.
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="year">Year</Label>
                      <Field
                        name="year"
                        type="number"
                        className={`form-control ${formik.touched.year && formik.errors.year ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage name="year" component="div" className="invalid-feedback" />
                      <small className="form-text text-muted">
                        Enter the manufacturing year of the vehicle, e.g.,
                        "2020".
                      </small>           
                                          </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="make">Make</Label>
                      <Field
                        name="make"
                        type="text"
                        className={`form-control ${formik.touched.make && formik.errors.make ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage name="make" component="div" className="invalid-feedback" />
                      <small className="form-text text-muted">
                        Enter the make of the vehicle, e.g., "Ford".
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="model">Model</Label>
                      <Field
                        name="model"
                        type="text"
                        className={`form-control ${formik.touched.model && formik.errors.model ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage name="model" component="div" className="invalid-feedback" />
                      <small className="form-text text-muted">
                        Enter the model of the vehicle, e.g., "F-150".
                      </small>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="type">Vehicle Type</Label>
                      <Field
                        name="type"
                        as="select"
                        className={`form-control ${formik.touched.type && formik.errors.type ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select Vehicle Type</option>
                        <option value="Truck">Truck</option>
                        <option value="Trailer">Trailer</option>
                      </Field>
                      <ErrorMessage name="type" component="div" className="invalid-feedback" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="status">Status</Label>
                      <Field
                        name="status"
                        as="select"
                        className={`form-control ${formik.touched.status && formik.errors.status ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Out of Service">Out of Service</option>
                      </Field>
                      <ErrorMessage name="status" component="div" className="invalid-feedback" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    {selectedPartner ? (
                      <FormGroup>
                        <Label htmlFor="partner">Partner</Label>
                        <Input
                          id="partner"
                          name="partner"
                          type="text"
                          value={selectedPartner.title}
                          disabled
                        />
                      </FormGroup>
                    ) : (
                      <IMPartnerSelector />
                    )}

                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="driver">Operator</Label>
                      <IMVendorUserComponent
                        memberID={selectedVehicle ? selectedVehicle.driverID : ""}
                        role="driver"
                        onSelect={value => formik.setFieldValue("driver", value)}
                      />
                      {formik.touched.driver && formik.errors.driver ? (
                        <FormFeedback>{formik.errors.driver}</FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="dispatch">Dispatch</Label>
                      <IMVendorUserComponent
                        memberID={selectedVehicle ? selectedVehicle.dispatchID : ""}
                        role="dispatch"
                        onSelect={value => formik.setFieldValue("dispatch", value)}
                      />
                      {formik.touched.dispatch && formik.errors.dispatch ? (
                        <FormFeedback>{formik.errors.dispatch}</FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <IMPhoto
                      formik={formik}
                      fieldName="photo"
                      currentPhoto={formik.values.photo}
                      onUpload={(url) => formik.setFieldValue("photo", url)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="registrationState">
                        Registration State/Province
                      </Label>
                      <Field
                        name="registrationState"
                        type="text"
                        className={`form-control ${formik.touched.registrationState && formik.errors.registrationState ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage name="registrationState" component="div" className="invalid-feedback" />
                    </FormGroup>
                  </Col>
                </Row>
                <Button type="submit" color="primary" disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? "Updating..." : "Update"}
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  className="ml-2"
                  onClick={() => navigate("/vehicles")}
                >
                  Back to Vehicle List
                </Button>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  </Row>
</div>
);
};

export default UpdateVehicle;