import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { fetchVehiclesRequest } from '../../store/vehicles/actions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Label, Button } from 'reactstrap';
import PartnerSelector from "../../components/ui/IMPartnerSelector";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';

const VehicleList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vehicles = useSelector(state => state.vehicles.vehicles); // Asegúrate de que estás obteniendo la data correcta
  const loading = useSelector(state => state.vehicles.loading);
  const selectedPartner = useSelector(state => state.partners.selectedPartner);
  const [vehicleType, setVehicleType] = useState('Truck');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const baseAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

  useEffect(() => {
    if (selectedPartner) {
      dispatch(fetchVehiclesRequest(selectedPartner.id, vehicleType));
    }
  }, [dispatch, selectedPartner, vehicleType]);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleVehicleTypeChange = (type) => {
    setVehicleType(type);
  };

  const handleReview = (vendorID, id) => {
    navigate(`/vehicle/${vendorID}/${id}/view`);
  };

  const handleEdit = (vendorID, id) => {
    navigate(`/vehicle/${vendorID}/${id}/update`);
  };

  const handleDelete = async (vendorID, id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      const url = `${baseAPIURL}vehicles/${vendorID}/${id}`;
      console.log('url delete ', url);
      const response = await fetch(url, {
        method: "DELETE",
      });
      window.location.reload(true);
    }
  };  
  const getStatusBadge = (status) => {
    const statusMap = {
      Active: 'success',
      Inactive: 'danger',
    };
    return <span className={`badge bg-${statusMap[status] || 'secondary'}`}>{status}</span>;
  };

  const data = {
    columns: [
      {
        label: 'Photo',
        field: 'photo',
        sort: 'disabled',
        width: 100
      },
      {
        label: 'Vehicle Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Make',
        field: 'make',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Year',
        field: 'year',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 200
      }
    ],
    rows: Array.isArray(vehicles.vehicles) ? vehicles.vehicles.map(vehicle => ({
      ...vehicle,
      photo: vehicle.photo ? (
        <img src={vehicle.photo} alt="Vehicle" style={{ width: '100px', height: '50px' }} />
      ) : (
        <i className="mdi mdi-truck" style={{ fontSize: '30px' }}></i>
      ),
      status: getStatusBadge(vehicle.status),
      actions: (
        <div>
          <Button color="info" size="sm" className="me-2" onClick={() => handleReview(selectedPartner.id, vehicle.id)}>Review</Button>
          <Button color="warning" size="sm" className="me-2" onClick={() => handleEdit(selectedPartner.id, vehicle.id)}>Edit</Button>
          <Button color="danger" size="sm" onClick={() => handleDelete(selectedPartner.id,vehicle.id)}>Delete</Button>
        </div>
      )
    })) : []
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Home" breadcrumbItem="Admin Vehicles" addRoute="/vehicle/add" />
        <Row className="mb-4">
          <Col md={4} className="d-flex align-items-center">
            <Label for="partnerSelector" className="me-2">Partner:</Label>
            <PartnerSelector id="partnerSelector" />
          </Col>
          <Col md={4} className="d-flex align-items-center">
            <Label for="vehicleType" className="me-2">Type:</Label>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret id="vehicleType">
                {vehicleType}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleVehicleTypeChange('Truck')}>Truck</DropdownItem>
                <DropdownItem onClick={() => handleVehicleTypeChange('Trailer')}>Trailer</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col md={4} className="text-end">
            <Button color="primary" onClick={() => navigate("/vehicle/add")}>
              <i className="mdi mdi-plus me-2"></i> Add New
            </Button>
          </Col>
        </Row>
        <div className="vehicle-list">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <MDBDataTable
              striped
              bordered
              small
              data={data}
              sortable
              searching={true}
              paging={true}
              responsive
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default VehicleList;