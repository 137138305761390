import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  FormGroup,
  FormText
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addCustomerRequest } from "../../store/customers/actions";
import IMPartnerSelector from "../../components/ui/IMPartnerSelector";
import IMMasterDropdown from "../../components/ui/IMMasterDropdown";
import IMMasterSubCategoryMultiSelect from '../../components/ui/IMMasterSubCategoryMultiSelect';
import IMMasterMultiSelect from "../../components/ui/IMMasterMultiSelect"; // Import the multi-select component
import IMLocation from "../../components/ui/IMLocation";
import { useNavigate } from "react-router-dom";
import IMPhoto from "../../components/ui/IMPhoto";

const AddNewCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.customers);
  const selectedPartner = useSelector(state => state.partners.selectedPartner);

  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Partner Name"),
    email: Yup.string().email("Invalid email format").required("Please Enter Email"),
    status: Yup.string().required("Please Select Status"),
    services: Yup.array().min(1, "Select at least one service").required("Please Select Services"),
    location: Yup.object().shape({
      label: Yup.string().required("Please select a location")
    }).nullable().required("Please select a location"),
  });

  const handleSubmit = (values) => {
    const customerData = {
      ...values,
      vendor: selectedPartner,
      vendorID: selectedPartner.id,
    };
    dispatch(addCustomerRequest(customerData, navigate));
  };

  const initialValues = {
    name: "",
    email: "",
    status: "",
    services: [],
    location: null,
    photo: "",
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Customers" breadcrumbItem="Add New Customer" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="name">Customer Name</Label>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            invalid={
                              formik.touched.name && !!formik.errors.name
                            }
                          />
                          <FormFeedback>{formik.errors.name}</FormFeedback>
                          <FormText color="muted">
                            Enter the name of the customer.
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            invalid={
                              formik.touched.email && !!formik.errors.email
                            }
                          />
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                          <FormText color="muted">
                            Enter the email address of the customer.
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <IMMasterDropdown
                            collectionName="status"
                            selectedValue={formik.values.status}
                            onChange={option =>
                              formik.setFieldValue("status", option.label)
                            }
                            label="Status"
                          />
                          <FormFeedback>{formik.errors.status}</FormFeedback>
                          <FormText color="muted">
                            Select the status of the customer.
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <IMMasterSubCategoryMultiSelect
                            collectionName="poNq3omWLHyHDtIlnr7o" // o el nombre de la colección relevante
                            selectedValues={formik.values.services}
                            onChange={value =>
                              formik.setFieldValue("services", value)
                            }
                            label="Subcategories"
                          />
                          <FormFeedback>{formik.errors.services}</FormFeedback>
                          <FormText color="muted">
                            Select one or more services for the customer.
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <IMLocation
                          label="Location"
                          selectedLocation={formik.values.location}
                          onLocationSelect={location =>
                            formik.setFieldValue("location", location)
                          }
                          formik={formik}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <IMPhoto
                          currentPhoto={formik.values.photo}
                          onUpload={url => formik.setFieldValue("photo", url)}
                          fieldName="photo"
                          formik={formik}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? "Loading..." : "Create"}
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      className="ml-2"
                      onClick={() => window.history.back()}
                    >
                      Back to Customers List
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
};

export default AddNewCustomer;