import {
  SET_SELECTED_PARTNER,
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  FETCH_PARTNER_REQUEST,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_FAILURE,
  ADD_PARTNER_REQUEST,
  ADD_PARTNER_SUCCESS,
  ADD_PARTNER_FAILURE,
  UPDATE_PARTNER_REQUEST,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAILURE,
} from "./types"

const initialState = {
  selectedPartner: null,
  partners: [],
  loading: false,
  error: null,
};

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PARTNER:
      return {
        ...state,
        selectedPartner: action.payload,
      }
    case FETCH_PARTNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: action.payload,
        loading: false,
      }
    case FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case FETCH_PARTNER_REQUEST:
      return { ...state, loading: true, error: null }
    case FETCH_PARTNER_SUCCESS:
      return { ...state, loading: false, selectedPartner: action.payload }
    case FETCH_PARTNER_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case ADD_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADD_PARTNER_SUCCESS:
      return {
        ...state,
        partners: [...state.partners, action.payload],
        loading: false,
      }
    case ADD_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_PARTNER_REQUEST:
      return { ...state, loading: true, error: null }
    case UPDATE_PARTNER_SUCCESS:
      return { ...state, loading: false, }
    case UPDATE_PARTNER_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
};

export default partnerReducer;