import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addMemberRequest } from '../../store/members/actions';
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  FormGroup,
  FormText
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import IMMasterDropdown from '../../components/ui/IMMasterDropdown';
import IMPhoto from "../../components/ui/IMPhoto";
import IMPartnerSelector from "../../components/ui/IMPartnerSelector";
import IMRolesSelectComponent from "../../components/ui/IMRoleSelectorComponent";
import { useNavigate } from "react-router-dom";

const AddNewMember = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPartner = useSelector((state) => state.partners.selectedPartner);
  const { loading, error } = useSelector((state) => state.members);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please Enter First Name"),
    lastName: Yup.string().required("Please Enter Last Name"),
    email: Yup.string().email("Invalid email format").required("Please Enter Email"),
    roles: Yup.array().min(1, "Select at least one role").required("Please Select Roles"),
    status: Yup.object().shape({
      value: Yup.string().required("Please Select Status"),
      label: Yup.string().required("Please Select Status")
    }).required("Please Select Status"),
  });

  const handleSubmit = (values) => {
    const memberData = {
      ...values,
      vendorID: selectedPartner.id,
      vendor: selectedPartner,
      status: values.status.label  // Asegúrate de guardar el label del status
    };
    dispatch(addMemberRequest(memberData, navigate));
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    roles: [],
    status: { value: "", label: "" },
    photo: "",
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Members" breadcrumbItem="Add New Member" />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {error && <div className="alert alert-danger">{error}</div>}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="firstName">First Name</Label>
                          <Input
                            id="firstName"
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            invalid={formik.touched.firstName && !!formik.errors.firstName}
                          />
                          <FormFeedback>{formik.errors.firstName}</FormFeedback>
                          <FormText color="muted">Enter the first name of the member.</FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="lastName">Last Name</Label>
                          <Input
                            id="lastName"
                            name="lastName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            invalid={formik.touched.lastName && !!formik.errors.lastName}
                          />
                          <FormFeedback>{formik.errors.lastName}</FormFeedback>
                          <FormText color="muted">Enter the last name of the member.</FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            invalid={formik.touched.email && !!formik.errors.email}
                          />
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                          <FormText color="muted">Enter the email address of the member.</FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="roles">Roles</Label>
                          <IMRolesSelectComponent
                            selectedRoles={formik.values.roles}
                            onChange={(value) => formik.setFieldValue("roles", value)}
                          />
                          <FormFeedback>{formik.errors.roles}</FormFeedback>
                          <FormText color="muted">Select one or more roles for the member.</FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="status">Status</Label>
                          <IMMasterDropdown
                            collectionName="status"
                            selectedValue={formik.values.status}
                            onChange={(option) => formik.setFieldValue("status", option)}
                            label="Status"
                          />
                          <FormFeedback>{formik.errors.status}</FormFeedback>
                          <FormText color="muted">Select the status of the member.</FormText>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        {selectedPartner ? (
                          <FormGroup>
                            <Label htmlFor="company">Partner</Label>
                            <Input
                              id="company"
                              name="company"
                              type="text"
                              value={selectedPartner.title}
                              disabled
                            />
                          </FormGroup>
                        ) : (
                          <IMPartnerSelector />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <IMPhoto
                          currentPhoto={formik.values.photo}
                          onUpload={(url) => formik.setFieldValue("photo", url)}
                          fieldName="photo"
                          formik={formik}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? "Loading..." : "Create"}
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      className="ml-2"
                      onClick={() => window.history.back()}
                    >
                      Back to Member List
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddNewMember;