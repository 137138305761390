export const FETCH_MEMBERS_REQUEST = "FETCH_MEMBERS_REQUEST";
export const FETCH_MEMBERS_SUCCESS = "FETCH_MEMBERS_SUCCESS";
export const FETCH_MEMBERS_FAILURE = "FETCH_MEMBERS_FAILURE";

export const FETCH_MEMBER_REQUEST = "FETCH_MEMBER_REQUEST";
export const FETCH_MEMBER_SUCCESS = "FETCH_MEMBER_SUCCESS";
export const FETCH_MEMBER_FAILURE = "FETCH_MEMBER_FAILURE";

export const ADD_MEMBER_REQUEST = "ADD_MEMBER_REQUEST";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_FAILURE = "ADD_MEMBER_FAILURE";

export const UPDATE_MEMBER_REQUEST = "UPDATE_MEMBER_REQUEST";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAILURE = "UPDATE_MEMBER_FAILURE";