import { takeLatest, call, put } from 'redux-saga/effects';
import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE } from '../types';
import { getMasterItems }  from '../../../helpers/firebase_mastesTables_helper';

function* fetchStatusSaga() {
  try {
    console.log('fetchStatusSaga')
    const status = yield call(getMasterItems, 'status');
    yield put({ type: FETCH_STATUS_SUCCESS, payload: status });
  } catch (error) {
    yield put({ type: FETCH_STATUS_FAILURE, payload: error.message });
  }
}

export default function* statusSaga() {
  yield takeLatest(FETCH_STATUS_REQUEST, fetchStatusSaga);
}