import PropTypes from "prop-types";
import React, { useEffect, useCallback, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link, useLocation } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const location = useLocation();
  const ref = useRef();
  const path = location.pathname;

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")}</li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-home"></i>
                <span className="badge rounded-pill bg-primary float-end">1</span>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            
            <li>
              <Link to="/customers" className="waves-effect">
                <i className="mdi mdi mdi-factory"></i>
                <span>{props.t("Customers")}</span>
              </Link>
            </li> 
            <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi mdi-cog-outline"></i>
                <span>{props.t("Admin")}</span>
              </Link>
              <ul className="sub-menu">
              
                <li><Link to="/partners">{props.t("Partners")}</Link></li>
                <li><Link to="/admin/status">{props.t("status")}</Link></li>
                <li><Link to="/admin/roles">{props.t("roles")}</Link></li>
                <li><Link to="/admin/services">{props.t("services")}</Link></li>
                <li><Link to="/admin/servicesManager">{props.t("services Manager")}</Link></li>
                <li><Link to="/admin/typeVehicles">{props.t("vehicletypes")}</Link></li>
              </ul>
            </li>
            
            <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-cash-outline"></i>
                <span>{props.t("Business")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/business/list">{props.t("Business List")}</Link></li>
                <li><Link to="/business/deals">{props.t("Deals")}</Link></li>
                <li><Link to="/business/new">{props.t("New Business")}</Link></li>
                <li><Link to="/business/historic">{props.t("Historic")}</Link></li>
              </ul>
            </li>
            
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-truck"></i>
                <span>{props.t("Vehicles")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/vehicles">{props.t("Vehicle List")}</Link>
                </li>
                <li>
                  <Link to="/vehicles/assignments">
                    {props.t("Vehicle Assignments")}
                  </Link>
                </li>
                <li>
                  <Link to="/vehicles/meter-history">
                    {props.t("Meter History")}
                  </Link>
                </li>
              </ul>
            </li>
           
            <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-clipboard-text-search-outline"></i>
                <span>{props.t("Inspections")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/inspections/list">{props.t("Inspections List")}</Link></li>
              </ul>
            </li>
            <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-send-circle-outline"></i>
                <span>{props.t("Dispatch")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/dispatch/list">{props.t("Vehicle List")}</Link></li>
                <li><Link to="/dispatch/assignments">{props.t("Vehicle Assignments")}</Link></li>
                <li><Link to="/dispatch/meter-history">{props.t("Meter History")}</Link></li>
              </ul>
            </li>
            <li>
              <Link to="/members" className="waves-effect">
                <i className="mdi mdi-contacts"></i>
                <span>{props.t("Members")}</span>
              </Link>
            </li>
            <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-inbox-full"></i>
                <span>{props.t("Communication")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/communication/inbox">{props.t("Inbox")}</Link></li>
                <li><Link to="/communication/outbox">{props.t("Outbox")}</Link></li>
              </ul>
            </li>
            <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Tasks")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/tasks/list">{props.t("Task List")}</Link></li>
                <li><Link to="/tasks/kanban">{props.t("Kanban Board")}</Link></li>
                <li><Link to="/tasks/create">{props.t("Create Task")}</Link></li>
              </ul>
            </li>
            <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-circle-outline"></i>
                <span>{props.t("Assistant AI")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/ai/support">{props.t("Support AI")}</Link></li>
                <li><Link to="/ai/mechanic">{props.t("Mechanic AI")}</Link></li>
              </ul>
            </li>
            <li className="menu-title">{props.t("Monetization")}</li>
            <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-checkbox-multiple-blank-outline"></i>
                <span>{props.t("Refer")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/refer/driver">{props.t("Refer to driver")}</Link></li>
                <li><Link to="/refer/client">{props.t("Refer to client")}</Link></li>
                <li><Link to="/refer/balance">{props.t("Money Balance")}</Link></li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-map-marker-outline"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps/Trucks">{props.t("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps/deals">{props.t("Vector Maps")}</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
