import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from '../../helpers/api_helper';

const baseAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:4000/api/';

function IMVendorUserComponent(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [typeaheadValue, setTypeaheadValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isTypeaheadVisible, setIsTypeaheadVisible] = useState(false);

  const { onSelect, role, memberID } = props;
  const selectedPartner = useSelector(state => state.partners.selectedPartner);
  const vendorID = selectedPartner ? selectedPartner.id : null;
// console.log('memberID',selectedPartner)
  useEffect(() => {
    if (!memberID) {
      setIsLoading(false);
      return;
    }
    const url = `${baseAPIURL}members/${vendorID}/${memberID}`;
    console.log('Imvendorcompo url',url)
    get(url)
      .then(data => {
        if (data) {
          setInputValue(`${data.firstName} ${data.lastName}`);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
      });
  }, [memberID]);

  useEffect(() => {
    if (typeaheadValue === null) {
      return;
    }

    const fetchData = async () => {
      try {
        const url = `${baseAPIURL}members/${vendorID}/users?role=${role}&limit=10&search=${typeaheadValue}`;
        const data = await get(url);
        if (data && data.users) {
          setUsers(data.users);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [typeaheadValue, vendorID, role]);

  const handleChange = (event) => {
    const text = event.target.value;
    const search = `firstName = ${text}`
    setTypeaheadValue(search);
    setInputValue(text);
  };

  const onFocus = () => {
    setIsTypeaheadVisible(true);
  };

  const onBlur = () => {
    setIsTypeaheadVisible(false);
  };

  const onClick = (data) => {
    setInputValue(`${data.firstName} ${data.lastName}`);
    setTypeaheadValue('');
    if (onSelect) onSelect(data);
    setIsTypeaheadVisible(false);
  };

  const listItems = users.map((data) => (
    <li onClick={() => onClick(data)} key={data.userID} style={{ padding: '10px', cursor: 'pointer' }}>
      <table>
        <tbody>
          <tr>
            <td>
              {data.photo ? (
                <img src={data.photo} alt={`${data.firstName} ${data.lastName}`} style={{ width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%' }} />
              ) : (
                <i className="mdi mdi-account-circle" style={{ fontSize: '25px', color: '#adb5bd', verticalAlign: 'middle' , marginRight: '15px'}}></i>
              )}
            </td>
            <td><span>{data.firstName} {data.lastName} ({data.email})</span></td>
          </tr>
        </tbody>
      </table>
    </li>
  ));

  return (
    <div className="TypeaheadComponent" style={{ position: 'relative' }}>
      <input
        className="FormTextField"
        autoComplete="off"
        onFocus={onFocus}
        type="text"
        name={role}
        value={inputValue}
        onChange={handleChange}
        style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ced4da' }}
      />
      {isTypeaheadVisible && (
        <div className="TypeaheadResultsContainer" style={{ position: 'absolute', top: '100%', left: 0, right: 0, zIndex: 1000, backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '4px', maxHeight: '200px', overflowY: 'auto' }}>
          <ul className="TypeaheadResultsList" id={memberID} style={{ listStyle: 'none', margin: 0, padding: '10px' }}>
            {listItems}
          </ul>
        </div>
      )}
      {isLoading && <div>Loading...</div>}
    </div>
  );
}

export default IMVendorUserComponent;