import { call, all, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_MEMBERS_REQUEST,
  ADD_MEMBER_REQUEST,
  UPDATE_MEMBER_REQUEST,
  FETCH_MEMBER_REQUEST,
} from "./actionTypes";
import {
  fetchMembersSuccess,
  fetchMembersFailure,
  addMemberFailure,
  addMemberSuccess,
  updateMemberSuccess,
  updateMemberFailure,
  fetchMemberSuccess,
  fetchMemberFailure,
} from "./actions";
import { get, post, put as apiPut } from '../../helpers/api_helper'; // Renombrar 'put' a 'apiPut'

function* fetchMembers(action) {
  try {
    const { companyId, memberType } = action.payload;
    console.log('companyId', companyId, 'memberType', memberType);
    const response = yield call(get, `/members?vendorID=${companyId}`);
    yield put(fetchMembersSuccess(response));
  } catch (error) {
    yield put(fetchMembersFailure(error.message));
  }
}

function* fetchMember(action) {
  try {
    const { vendorID, memberId } = action.payload;
    const url = `/members/${vendorID}/${memberId}`;
    console.log('urlfetch member', url);
    const response = yield call(get, url);
    console.log('response fetchMember', response);
    yield put(fetchMemberSuccess(response));
  } catch (error) {
    yield put(fetchMemberFailure(error.message));
  }
}

function* addMember(action) {
  try {
    const response = yield call(post, '/members/add', action.payload.member);
    console.log('response add', response);
    yield put(addMemberSuccess(response));
    action.payload.navigate("/members");
  } catch (error) {
    yield put(addMemberFailure(error.message));
    action.payload.navigate("/members"); // O manejar el error de otra forma
  }
}

function* updateMember(action) {
  try {
    const { member, navigate } = action.payload;
    const url = `members/${member.vendorID}/${member.userID}`;
    console.log("updateMember url", url);
    const response = yield call(apiPut, url, member);
    yield put(updateMemberSuccess(response));
    navigate("/members");
  } catch (error) {
    console.log('error.message', error.message);
    yield put(updateMemberFailure(error.message));
    navigate("/members");
  }
}

export function* watchFetchMembers() {
  yield takeLatest(FETCH_MEMBERS_REQUEST, fetchMembers);
}

export function* watchFetchMember() {
  yield takeLatest(FETCH_MEMBER_REQUEST, fetchMember);
}

export function* watchAddMember() {
  yield takeLatest(ADD_MEMBER_REQUEST, addMember);
}

export function* watchUpdateMember() {
  yield takeLatest(UPDATE_MEMBER_REQUEST, updateMember);
}

export default function* memberSaga() {
  yield all([
    watchFetchMembers(),
    watchFetchMember(),
    watchAddMember(),
    watchUpdateMember(),
  ]);
}