export const FETCH_VEHICLES_REQUEST = "FETCH_VEHICLES_REQUEST";
export const FETCH_VEHICLES_SUCCESS = "FETCH_VEHICLES_SUCCESS";
export const FETCH_VEHICLES_FAILURE = "FETCH_VEHICLES_FAILURE";

export const FETCH_VEHICLE_REQUEST = "FETCH_VEHICLE_REQUEST";
export const FETCH_VEHICLE_SUCCESS = "FETCH_VEHICLE_SUCCESS";
export const FETCH_VEHICLE_FAILURE = "FETCH_VEHICLE_FAILURE";

export const ADD_VEHICLE_REQUEST = "ADD_VEHICLE_REQUEST";
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS";
export const ADD_VEHICLE_FAILURE = "ADD_VEHICLE_FAILURE";

export const UPDATE_VEHICLE_REQUEST = "UPDATE_VEHICLE_REQUEST";
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS";
export const UPDATE_VEHICLE_FAILURE = "UPDATE_VEHICLE_FAILURE";