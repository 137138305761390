import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchMasterItemsRequest, addMasterItemRequest, updateMasterItemRequest, deleteMasterItemRequest, uploadPhotoRequest } from '../../store/masters/actions';
import { makeSelectMasterItems } from '../../store/masters/selectors';
import { Button, Table, Form, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, FormText } from 'reactstrap';

const IMMasterTable = ({ collectionName }) => {
  const dispatch = useDispatch();
  
  const selectMasterItems = makeSelectMasterItems(collectionName);
  const { masterItems, loading, error, photoUrl } = useSelector(
    createStructuredSelector({
      masterItems: selectMasterItems,
      loading: state => state.masters.loading,
      error: state => state.masters.error,
      photoUrl: state => state.masters.photoUrl
    })
  );

  const [modal, setModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(fetchMasterItemsRequest(collectionName));
  }, [dispatch, collectionName]);

  useEffect(() => {
    if (photoUrl) {
      setCurrentItem(prevState => ({ ...prevState, photo: photoUrl }));
    }
  }, [photoUrl]);

  const toggleModal = () => setModal(!modal);

  const handleAdd = () => {
    setCurrentItem({});
    setIsEditing(false);
    toggleModal();
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setIsEditing(true);
    toggleModal();
  };

  const handleDelete = (itemId) => {
    dispatch(deleteMasterItemRequest(collectionName, itemId));
    setTimeout(() => {
      dispatch(fetchMasterItemsRequest(collectionName));
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      dispatch(updateMasterItemRequest(collectionName, currentItem.id, currentItem));
    } else {
      dispatch(addMasterItemRequest(collectionName, currentItem));
    }
    toggleModal();
    setTimeout(() => {
      dispatch(fetchMasterItemsRequest(collectionName));
    }, 1000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem(prevState => ({ ...prevState, [name]: value }));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    dispatch(uploadPhotoRequest(file));
  };

  return (
    <div>
      <h2>{collectionName}</h2>
      <Button color="primary" onClick={handleAdd}>Add New Item</Button>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Photo</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {masterItems.map(item => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>
                {item.photo ? (
                  <img src={item.photo} alt="item" style={{ width: '50px', height: '50px' }} />
                ) : (
                  <span>No photo</span>
                )}
              </td>
              <td>
                <Button color="warning" onClick={() => handleEdit(item)}>Edit</Button>
                <Button color="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={modal} toggle={toggleModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{isEditing ? 'Edit Item' : 'Add Item'}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                name="name"
                value={currentItem.name || ''}
                onChange={handleChange}
                placeholder="Enter name"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="description">Description</Label>
              <Input
                name="description"
                value={currentItem.description || ''}
                onChange={handleChange}
                placeholder="Enter description"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="photo">Photo</Label>
              {currentItem.photo && (
                <div className="mb-3">
                  <img src={currentItem.photo} alt="item" style={{ width: '150px', height: '150px' }} />
                </div>
              )}
              <Input
                type="file"
                name="photo"
                onChange={handlePhotoUpload}
              />
              <FormText color="muted">Upload a photo of the item.</FormText>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">{isEditing ? 'Update' : 'Add'}</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default IMMasterTable;